import Debug from 'debug';
import { select, put, all, takeLatest, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import * as chainActions from '../actions/chain';
import * as notifierActions from '../actions/notifier';
import { isSupportedChain, chains, getChainKey } from '../../utils/chain';
import { capitalizeFirstLetter } from '../../utils/format';
import { DEFAULT_CHAIN } from '../../config';
import { multiWeb3 } from '../../services/web3';

const debug = Debug('sagas:chain');

const getRouterChain = (router, { strict = false } = {}) => {
  const chainKey = router.location.pathname.split('/')[1];
  const lowerCaseName = chainKey ? chainKey.toLowerCase() : undefined;
  if (!lowerCaseName && strict) throw Error('Chain undefined');
  return lowerCaseName;
};

const getRouterChainId = (router, { strict = false } = {}) => {
  const chainKey = getRouterChain(router, { strict });
  const chain = chains[chainKey];
  if (!chain && strict) throw Error('Unknown chain');
  const chainId = chain.id || DEFAULT_CHAIN;
  return chainId;
};

export function* refreshChain(action) {
  debug('refreshChain()', action);
  const { connected, chainId: web3ChainId, provider } = multiWeb3.getStatus();
  const router = yield select((state) => state.router);
  const initialChainId = yield select((state) => state.chain.chainId);
  const actionChainId =
    action &&
    (action.chainId ||
      (chains[action.chainName] && chains[action.chainName].id));
  const routerChainId = getRouterChainId(router);
  let chainId = initialChainId;

  if (connected) {
    debug(`web3provider connected to ${web3ChainId}`);
    if (isSupportedChain(web3ChainId)) {
      chainId = web3ChainId;
    } else {
      debug(
        `web3provider chain ${web3ChainId} is not supported, fallback to default ${DEFAULT_CHAIN}`,
      );
      const providerDisplay = capitalizeFirstLetter(provider);
      yield put(
        notifierActions.notify({
          level: 'warning',
          message: `${providerDisplay} current chain ${web3ChainId} is not supported.
          Use ${providerDisplay} to switch chain`,
        }),
      );
      chainId = DEFAULT_CHAIN;
    }
  } else if (actionChainId) {
    debug('chain changed by user');
    if (isSupportedChain(actionChainId)) {
      chainId = actionChainId || initialChainId;
    } else {
      debug(`user chain ${actionChainId} is not supported`);
    }
  } else if (routerChainId) {
    debug("fallback to browser's chain");
    if (isSupportedChain(routerChainId)) {
      chainId = routerChainId;
    } else {
      debug(`browser chain ${routerChainId} is not supported`);
    }
  }

  debug(`chainId: ${chainId}`);
  if (initialChainId !== chainId) {
    yield put(chainActions.setCurrentChain(chainId));
  } else {
    debug("chain didn't changed");
  }

  if (routerChainId !== chainId) {
    const pathSplit = router.location.pathname.split('/');
    const chainKey = getChainKey(chainId);
    debug(`routing to selected chain ${chainId} (${chainKey})`);
    if (chainKey) pathSplit[1] = chainKey;
    const newURL = pathSplit.join('/');
    yield put(push(newURL));
  } else {
    debug("route didn't changed");
  }
}

export function* watchChain() {
  // on load trigger
  yield takeEvery('STARTUP', refreshChain);
  // user action trigger
  yield takeLatest('CHANGE_CHAIN', refreshChain);
  // web3provider trigger
  yield takeLatest('PROVIDER_DISCONNECTED', refreshChain);
  yield takeLatest('PROVIDER_ENABLED', refreshChain);
  yield takeLatest('PROVIDER_NETWORK_CHANGED', refreshChain);
}

export default function* chainSaga() {
  yield all([watchChain()]);
}
