import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSubscription, gql } from '@apollo/client';
import { chainIdSelector } from '../../store/selectors/chain';
import Deals from '../presentational/Deals';

const PAGE_LENGTH = 20;

const DEALS_SUBSCRIPTION = gql`
  subscription Deals($length: Int = 20, $skip: Int = 0) {
    deals(
      first: $length
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
    ) {
      dealid: id
      timestamp
      requester {
        address: id
      }
      beneficiary {
        address: id
      }
      callback {
        address: id
      }
      app {
        address: id
      }
      dataset {
        address: id
      }
      workerpool {
        address: id
      }
      category {
        catid: id
        workClockTimeRef
      }
      startTime
      appPrice
      datasetPrice
      workerpoolPrice
      botSize
      trust
      completedTasksCount
      claimedTasksCount
    }
  }
`;

const DEALS_HAS_NEXT_SUBSCRIPTION = gql`
  subscription NextDeal($next: Int = 0) {
    next: deals(
      first: 1
      skip: $next
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
    }
  }
`;

export default function DealsContainer() {
  const [page, setPage] = useState(0);
  const chainId = useSelector(chainIdSelector);

  useEffect(() => {
    setPage(0);
  }, [chainId]);

  const sub = useSubscription(DEALS_SUBSCRIPTION, {
    variables: { skip: PAGE_LENGTH * page, length: PAGE_LENGTH },
  });
  const subHasNext = useSubscription(DEALS_HAS_NEXT_SUBSCRIPTION, {
    variables: { next: PAGE_LENGTH * (page + 1) },
  });

  const loading = sub.loading || subHasNext.loading;

  const deals = (!loading && sub?.data?.deals) || [];
  const hasNext = !loading && subHasNext.data?.next?.length === 1;

  const passedProps = {
    pageSize: PAGE_LENGTH,
    loading,
    page,
    setPage,
    hasNext,
    deals,
  };

  return <Deals {...passedProps} />;
}
