import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getBlockExplorerUrl, getChainKey } from '../../utils/chain';
import LinkButton from './LinkButton';
import CopyButton from './CopyButton';
import TextNoWrap from './TextNoWrap';

const TxLink = (props) => {
  const { txHash, chainId, hideInternalLink = false } = props;
  const chainKey = getChainKey(chainId);
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  return (
    <TextNoWrap>
      {hideInternalLink || !chainKey ? (
        <>{txHash}</>
      ) : (
        <Link
          className={classNames('hint--right', 'internal')}
          aria-label="view transaction details"
          to={`/${chainKey}/tx/${txHash}`}
        >
          {txHash}
        </Link>
      )}
      {blockExplorerUrl && (
        <LinkButton
          url={`${blockExplorerUrl}/tx/${txHash}`}
          hint="open in blockchain explorer"
        />
      )}
      <CopyButton toClipboard={txHash} />
    </TextNoWrap>
  );
};

export default TxLink;
