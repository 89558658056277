import React from 'react';
import CopyButton from './CopyButton';

const Bytes = (props) => {
  const { children: raw } = props;
  const chunks = raw?.match(/(.{1,66})/g) || [];
  return (
    <div style={{ display: 'inline-block', fontFamily: 'monospace' }}>
      {chunks.map((chunk, i) => (
        <div key={i}>
          {chunk}
          {i === chunks.length - 1 && <CopyButton toClipboard={raw} />}
        </div>
      ))}
    </div>
  );
};

export default Bytes;
