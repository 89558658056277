import React from 'react';
import DealLink from './DealLink';
import TextNoWrap from './TextNoWrap';
import TxLink from './TxLink';
import WorkerpoolLink from './WorkerpoolLink';

const DealEvent = (props) => {
  const { eventName, type, dealid, workerpool, txHash, chainId } = props;

  return (
    <>
      {(eventName || type) && <>{eventName || type} </>}
      {txHash && (
        <>
          <TextNoWrap>
            <TxLink txHash={txHash} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {dealid && (
        <>
          <TextNoWrap>
            {'deal '}
            <DealLink dealid={dealid} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {workerpool && (
        <>
          <TextNoWrap>
            {'workerpool '}
            <WorkerpoolLink address={workerpool} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
    </>
  );
};

export default DealEvent;
