import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import WorkerpoolLink from './WorkerpoolLink';
import Details from './Details';
import AddressLink from './AddressLink';
import Loading from './Loading';
import NotFound from './NotFound';
import TransferEvent from './TransferEvent';
import ContentWrapper from './ContentWrapper';
import Deals from './Deals';

const styles = (theme) => ({
  spacer: {
    height: '2rem',
  },
});

const useStyles = makeStyles(styles);

const Workerpool = (props) => {
  const { loading, workerpool, chainId, workerpoolDeals } = props;

  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(workerpool).length === 0) {
    return <NotFound />;
  }
  const mintEvent =
    Array.isArray(workerpool?.transfers) && workerpool?.transfers[0];

  const details = {
    ...(workerpool.address && {
      Address: (
        <WorkerpoolLink
          address={workerpool.address}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    ...(workerpool.description && { Description: workerpool.description }),
    ...(workerpool.owner && {
      Owner: <AddressLink address={workerpool.owner} chainId={chainId} />,
    }),
    ...(mintEvent?.timestamp && {
      Created: (
        <>
          {moment(mintEvent.timestamp * 1000).fromNow()} (
          {moment(mintEvent.timestamp * 1000).toString()})
        </>
      ),
    }),
    ...(Array.isArray(workerpool?.transfers) && {
      Transfers: workerpool.transfers.map((transferEvent, i) => (
        <TransferEvent {...transferEvent} chainId={chainId} key={i} />
      )),
    }),
  };

  return (
    <ContentWrapper>
      <Details title="Workerpool details" details={details} />
      <div className={classes.spacer} />
      <Deals {...workerpoolDeals} hideColumns={['workerpool']} />
    </ContentWrapper>
  );
};

export default Workerpool;
