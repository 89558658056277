import React from 'react';
import Loading from './Loading';
import Message from './Message';
import NotFound from './NotFound';

const Search = (props) => {
  const { loading, isValid } = props;

  if (!isValid) {
    return <Message>Invalid search, try with an address or an ID</Message>;
  }
  if (loading) {
    return <Loading />;
  }
  return <NotFound />;
};

export default Search;
