import React from 'react';

const SuccessCell = ({ successRate, timeoutTimestamp = 0 }) => {
  const timeout = timeoutTimestamp && timeoutTimestamp < Date.now();
  let color;
  if (successRate === 1) {
    color = '#11B15E';
  } else if (!timeout) {
    color = '#F4C503';
  } else {
    color = '#EF5353';
  }
  return (
    <div
      style={{
        color: color,
      }}
    >
      {successRate * 100}%
    </div>
  );
};

export default SuccessCell;
