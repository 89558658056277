import { combineReducers } from 'redux';

function showProviderModal(state = false, action) {
  switch (action.type) {
    case 'SET_SHOW_PROVIDER_MODAL':
      return action.bool;
    default:
      return state;
  }
}

function showModal(state = false, action) {
  switch (action.type) {
    case 'SET_SHOW_ACCOUNT_MANAGER_MODAL':
      return action.bool;
    case 'PROVIDER_DISCONNECTED':
      return false;
    default:
      return state;
  }
}

function runGuide(state = true, action) {
  switch (action.type) {
    case 'SET_RUN_ACCOUNT_MANAGER_GUIDE':
      return action.bool;
    default:
      return state;
  }
}

function activeTab(state = 'deposit', action) {
  switch (action.type) {
    case 'SET_ACCOUNT_ACTIVE_TAB':
      return action.value;
    case 'CLEAR_ACCOUNT_MANAGER':
      return 'deposit';
    default:
      return state;
  }
}

export default combineReducers({
  showProviderModal,
  showModal,
  runGuide,
  activeTab,
});
