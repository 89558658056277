import BN from 'bn.js';
import Big from 'big.js';
import { BigNumber, utils as ethersUtils } from 'ethers';

export const zeroBN = new BN('0');

export const bnToEthersBn = (bn) => BigNumber.from(bn.toString());

export const ethersBnToBn = (ethersBn) => new BN(ethersBn.toString());

export const minBn = (bnArray) => {
  let min = new BN(bnArray[0]);
  bnArray.map((e) => {
    if (e.lt(min)) min = e;
    return min;
  });
  return min;
};

export const sumValues = (...values) => {
  try {
    Big.NE = -10;
    Big.PE = 18;
    let total = new Big(0);
    values.forEach((val) => {
      total = total.add(new Big(val));
    });
    return total.toString();
  } catch (error) {
    return '0';
  }
};

export const rlcToNano = (amount) => {
  try {
    Big.NE = -10;
    Big.PE = 18;
    const rlcAmount = new Big(amount.toString());
    const unitMultiplier = new Big(10).pow(9);
    return rlcAmount.times(unitMultiplier).toString();
  } catch (error) {
    return null;
  }
};

export const nanoToRlc = (amount) => {
  try {
    Big.NE = -10;
    Big.PE = 9;
    const nRlcAmount = new Big(amount.toString());
    const unitMultiplier = new Big(10).pow(-9);
    return nRlcAmount.times(unitMultiplier).toString();
  } catch (error) {
    return null;
  }
};

export const truncateBnWeiToBnNRlc = (bnWei) => {
  const weiString = bnWei.toString();
  const nRlcString = weiString.length > 9 ? weiString.slice(0, -9) : '0';
  return new BN(nRlcString);
};

export const bnNRlcToBnWei = (bnNRlc) => {
  const nRlcString = bnNRlc.toString();
  const weiString = nRlcString !== '0' ? nRlcString.concat('000000000') : '0';
  return new BN(weiString);
};

export const ethersToWei = (amount) => {
  try {
    return ethersUtils.parseEther(amount.toString()).toString();
  } catch (error) {
    return null;
  }
};
