export const ensLookupAsync = {
  request: ({ chainId, address }) => ({
    type: 'ENS_LOOKUP_REQUEST',
    chainId,
    address,
  }),
  success: ({ chainId, address, ens }) => ({
    type: 'ENS_LOOKUP_SUCCESS',
    chainId,
    address,
    ens,
  }),
  failure: ({ chainId, address }) => ({
    type: 'ENS_LOOKUP_FAILURE',
    chainId,
    address,
  }),
};
