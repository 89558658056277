const fetchAssetPrices = async (asset, quotes) => {
  const assetIdMap = {
    ETH: 'ethereum',
    RLC: 'iexec-rlc',
  };
  const quoteIdMap = {
    USD: 'usd',
    EUR: 'eur',
    JPY: 'jpy',
  };
  const assetId = assetIdMap[asset];
  if (!assetId) throw Error(`Unknown asset ${asset}`);
  const url = `https://api.coingecko.com/api/v3/coins/${assetId}`;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  });
  if (!response.ok) {
    throw Error(`Bad response ${response.status}`);
  }
  const json = await response.json();
  const res = quotes.reduce((acc, quote) => {
    const quoteId = quoteIdMap[quote];
    if (
      quoteId &&
      json.market_data &&
      json.market_data.current_price &&
      json.market_data.current_price[quoteId] !== undefined
    ) {
      acc[quote] = json.market_data.current_price[quoteId];
    }
    return acc;
  }, {});
  return res;
};

export const getRlcPrice = () => fetchAssetPrices('RLC', ['USD', 'JPY', 'EUR']);
export const getEthPrice = () => fetchAssetPrices('ETH', ['USD', 'JPY', 'EUR']);
