import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery, useSubscription } from '@apollo/client';
import App from '../presentational/App';
import { chainIdSelector } from '../../store/selectors/chain';

const PAGE_LENGTH = 10;

const APP_QUERY = gql`
  query AppSearch($searchId: ID!, $searchString: String!) {
    app(id: $searchId) {
      address: id
      name
      owner {
        address: id
      }
      multiaddr
      mrenclave
      checksum
      transfers(
        where: { app: $searchString }
        orderBy: timestamp
        orderDirection: asc
      ) {
        app {
          address: id
        }
        from {
          address: id
        }
        to {
          address: id
        }
        transaction {
          txHash: id
          timestamp
          blockNumber
        }
      }
    }
  }
`;

const APP_DEALS_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $dealsPageLength: Int = 20
    $dealsPage: Int = 0
    $dealsNextPage: Int = 20
  ) {
    app(id: $search) {
      address: id
      deals: usages(
        orderBy: timestamp
        orderDirection: desc
        first: $dealsPageLength
        skip: $dealsPage
      ) {
        dealid: id
        timestamp
        requester {
          address: id
        }
        beneficiary {
          address: id
        }
        callback {
          address: id
        }
        app {
          address: id
          name
        }
        dataset {
          address: id
          name
        }
        workerpool {
          address: id
          description
        }
        category {
          catid: id
          workClockTimeRef
        }
        startTime
        appPrice
        datasetPrice
        workerpoolPrice
        botSize
        trust
        completedTasksCount
        claimedTasksCount
      }
      dealsHasNext: usages(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $dealsNextPage
      ) {
        id
      }
    }
  }
`;

export default function AppContainer(props) {
  const { address } = props;
  const chainId = useSelector(chainIdSelector);

  const search = address?.toLowerCase();

  const [dealsPage, setDealsPage] = useState(0);

  useEffect(() => {
    setDealsPage(0);
  }, [chainId]);

  const appQuery = useQuery(APP_QUERY, {
    variables: { searchId: search, searchString: search },
  });

  const appQueryResults = !appQuery.loading ? appQuery?.data?.app : undefined;

  const loading = appQuery.loading;

  const app = {
    ...(appQueryResults?.address && { address: appQueryResults?.address }),
    ...(appQueryResults?.name && { name: appQueryResults?.name }),
    ...(appQueryResults?.owner && { owner: appQueryResults?.owner?.address }),
    ...(appQueryResults?.multiaddr && {
      multiaddr: appQueryResults?.multiaddr,
    }),
    ...(appQueryResults?.mrenclave && {
      mrenclave: appQueryResults?.mrenclave,
    }),
    ...(appQueryResults?.checksum && { checksum: appQueryResults?.checksum }),
    ...(appQueryResults?.transfers?.length > 0 && {
      transfers: appQueryResults?.transfers.map((transfer) => ({
        from: transfer?.from?.address,
        to: transfer?.to?.address,
        txHash: transfer?.transaction?.txHash,
        blockNumber: transfer?.transaction?.blockNumber,
        timestamp: transfer?.transaction?.timestamp,
      })),
    }),
  };

  const dealsSub = useSubscription(APP_DEALS_SUBSCRIPTION, {
    variables: {
      search: search,
      dealsPage: PAGE_LENGTH * dealsPage,
      dealsPageLength: PAGE_LENGTH,
      dealsNextPage: PAGE_LENGTH * (dealsPage + 1),
    },
  });

  const loadingDeals = dealsSub.loading;
  const dealsSubResults = !dealsSub.loading ? dealsSub?.data?.app : undefined;
  const deals = (!loadingDeals && dealsSubResults?.deals) || [];
  const dealsHasNext =
    !loadingDeals && dealsSubResults?.dealsHasNext?.length === 1;

  const passedProps = {
    loading,
    app,
    chainId,
    appDeals: {
      pageSize: PAGE_LENGTH,
      loading: loadingDeals,
      page: dealsPage,
      setPage: setDealsPage,
      hasNext: dealsHasNext,
      deals,
    },
  };

  return <App {...passedProps} />;
}
