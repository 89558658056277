import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { FaLink } from 'react-icons/fa';
import { useWalletManager } from '@iexec/react-wallet-manager';
import {
  getChainKey,
  isSupportedChain,
  UNSUPPORTED_CHAIN,
} from '../utils/chain';

export default withStyles((theme) => ({
  chainBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
  },
  chain: {
    width: '1.3em',
  },
  chip: {
    fontSize: '1em',
    height: '2em',
  },
  avatar: {
    height: '2em',
    width: '2em',
  },
}))(({ classes }) => {
  const { chainId } = useWalletManager();
  const label = !chainId
    ? 'Disconnected'
    : isSupportedChain(chainId)
    ? getChainKey(chainId)
    : UNSUPPORTED_CHAIN;
  return (
    <div className={classes.chainBox}>
      <Chip
        classes={{
          root: classes.chip,
        }}
        avatar={
          <Avatar classes={{ root: classes.avatar }}>
            <FaLink className={classes.chain} size={10} />
          </Avatar>
        }
        label={label}
      />
    </div>
  );
});
