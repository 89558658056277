import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gql, useQuery, useSubscription } from '@apollo/client';
import Workerpool from '../presentational/Workerpool';
import { chainIdSelector } from '../../store/selectors/chain';

const PAGE_LENGTH = 10;

const WORKERPOOL_QUERY = gql`
  query WorkerpoolSearch($searchId: ID!, $searchString: String!) {
    workerpool(id: $searchId) {
      address: id
      owner {
        address: id
      }
      description
      transfers(
        where: { workerpool: $searchString }
        orderBy: timestamp
        orderDirection: asc
      ) {
        workerpool {
          address: id
        }
        from {
          address: id
        }
        to {
          address: id
        }
        transaction {
          txHash: id
          timestamp
          blockNumber
        }
      }
    }
  }
`;

const WORKERPOOL_DEALS_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $dealsPageLength: Int = 20
    $dealsPage: Int = 0
    $dealsNextPage: Int = 20
  ) {
    workerpool(id: $search) {
      address: id
      deals: usages(
        orderBy: timestamp
        orderDirection: desc
        first: $dealsPageLength
        skip: $dealsPage
      ) {
        dealid: id
        timestamp
        requester {
          address: id
        }
        beneficiary {
          address: id
        }
        callback {
          address: id
        }
        app {
          address: id
          name
        }
        dataset {
          address: id
          name
        }
        workerpool {
          address: id
          description
        }
        category {
          catid: id
          workClockTimeRef
        }
        startTime
        appPrice
        datasetPrice
        workerpoolPrice
        botSize
        trust
        completedTasksCount
        claimedTasksCount
      }
      dealsHasNext: usages(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $dealsNextPage
      ) {
        id
      }
    }
  }
`;

export default function WorkerpoolContainer(props) {
  const { address } = props;
  const chainId = useSelector(chainIdSelector);

  const search = address?.toLowerCase();

  const [dealsPage, setDealsPage] = useState(0);

  useEffect(() => {
    setDealsPage(0);
  }, [chainId]);

  const workerpoolQuery = useQuery(WORKERPOOL_QUERY, {
    variables: { searchId: search, searchString: search },
  });

  const workerpoolQueryResults = !workerpoolQuery.loading
    ? workerpoolQuery?.data?.workerpool
    : undefined;

  const loading = workerpoolQuery.loading;

  const workerpool = {
    ...(workerpoolQueryResults?.address && {
      address: workerpoolQueryResults?.address,
    }),
    ...(workerpoolQueryResults?.description && {
      description: workerpoolQueryResults?.description,
    }),
    ...(workerpoolQueryResults?.owner && {
      owner: workerpoolQueryResults?.owner?.address,
    }),
    ...(workerpoolQueryResults?.checksum && {
      checksum: workerpoolQueryResults?.checksum,
    }),
    ...(workerpoolQueryResults?.transfers?.length > 0 && {
      transfers: workerpoolQueryResults?.transfers.map((transfer) => ({
        from: transfer?.from?.address,
        to: transfer?.to?.address,
        txHash: transfer?.transaction?.txHash,
        blockNumber: transfer?.transaction?.blockNumber,
        timestamp: transfer?.transaction?.timestamp,
      })),
    }),
  };

  const dealsSub = useSubscription(WORKERPOOL_DEALS_SUBSCRIPTION, {
    variables: {
      search: search,
      dealsPage: PAGE_LENGTH * dealsPage,
      dealsPageLength: PAGE_LENGTH,
      dealsNextPage: PAGE_LENGTH * (dealsPage + 1),
    },
  });

  const loadingDeals = dealsSub.loading;
  const dealsSubResults = !dealsSub.loading
    ? dealsSub?.data?.workerpool
    : undefined;
  const deals = (!loadingDeals && dealsSubResults?.deals) || [];
  const dealsHasNext =
    !loadingDeals && dealsSubResults?.dealsHasNext?.length === 1;

  const passedProps = {
    loading,
    workerpool,
    chainId,
    workerpoolDeals: {
      pageSize: PAGE_LENGTH,
      loading: loadingDeals,
      page: dealsPage,
      setPage: setDealsPage,
      hasNext: dealsHasNext,
      deals,
    },
  };

  return <Workerpool {...passedProps} />;
}
