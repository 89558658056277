import React from 'react';
import AddressLink from './AddressLink';
import TxLink from './TxLink';
import Details from './Details';
import Loading from './Loading';
import NotFound from './NotFound';
import TransferEvent from './TransferEvent';
import TaskEvent from './TaskEvent';
import DealEvent from './DealEvent';
import AccountEvent from './AccountEvent';
import ContentWrapper from './ContentWrapper';

const Transaction = (props) => {
  const { loading, transaction, chainId } = props;

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(transaction).length <= 1) {
    // "events" key always exist
    return <NotFound />;
  }

  const details = {
    ...(transaction.txHash && {
      'Tx Hash': (
        <TxLink
          txHash={transaction.txHash}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    ...(transaction.from && {
      From: <AddressLink address={transaction.from} chainId={chainId} />,
    }),
    ...(transaction.to && {
      To: <AddressLink address={transaction.to} chainId={chainId} />,
    }),
    ...(transaction.gasUsed && { 'Gas used': transaction.gasUsed }),
    ...(transaction.gasPrice && { 'Gas price': transaction.gasPrice }),
    ...(transaction.events && {
      Events: transaction.events.map((event, i) => {
        if (event?.type?.endsWith('Transfer')) {
          return <TransferEvent {...event} chainId={chainId} key={i} />;
        } else if (event?.type?.indexOf('Task') === 0) {
          return <TaskEvent {...event} chainId={chainId} key={i} />;
        } else if (
          event?.type === 'OrdersMatched' ||
          event?.type === 'SchedulerNotice'
        ) {
          return <DealEvent {...event} chainId={chainId} key={i} />;
        } else if (
          event?.type === 'Lock' ||
          event?.type === 'Unlock' ||
          event?.type === 'Seize' ||
          event?.type === 'Reward'
        ) {
          return <AccountEvent {...event} chainId={chainId} key={i} />;
        } else {
          return <div key={i}>{event.type}</div>;
        }
      }),
    }),
  };

  return (
    <ContentWrapper>
      <Details title="Transaction details" details={details} />
    </ContentWrapper>
  );
};

export default Transaction;
