import React from 'react';
import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  root: {
    padding: '3rem',
  },
});
const useStyles = makeStyles(styles);

const Message = (props) => {
  const { children } = props;
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default Message;
