const subDomain = '';

export const EXPLORER_BASE_URL = `https://${subDomain}explorer.iex.ec`;

export const DAPPSTORE_BASE_URL = `https://iex.ec/dappstore`;

export const IPFS_GATEWAY_URL = 'https://ipfs.iex.ec';

export const BLOCKCHAIN_EXPLORER_BASE_URL = {
  bellecour: 'https://blockscout-bellecour.iex.ec',
};

export const SUBGRAPH_HTTP_URL = {
  bellecour:
    'https://thegraph.bellecour.iex.ec/subgraphs/name/bellecour/poco-v5',
};

export const SUBGRAPH_WS_URL = {
  bellecour:
    'wss://ws-thegraph.bellecour.iex.ec/subgraphs/name/bellecour/poco-v5',
};

export const PORTIS_ID = process.env.REACT_APP_PORTIS_ID;

// comment here to remove chain
// default first
export const SUPPORTED_CHAIN_KEYS = ['bellecour'];

export const DEFAULT_CHAIN = 134;

export const INFURA_PROJECT_ID = process.env.REACT_APP_INFURA_PROJECT_ID;
