import React from 'react';
import ReactTable from 'react-table-6';
// import Debug from 'debug';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { ClipLoader } from 'react-spinners';

// const debug = Debug('Table');

export const DEFAULT_PAGE_SIZE = 20;

const tableStyles = (theme) => ({
  root: {
    border: 'none',
  },
  thGroup: {
    background: '#f5f7fa !important',
    border: 'none !important',
  },
  title: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    fontFamily: '"Space Mono", monospace',
  },
  thead: {
    boxShadow: 'none !important',
  },
  th: {
    textAlign: 'left',
  },
});

const Table = ({
  classes,
  className,
  pageNumber,
  data = [],
  pageSize = DEFAULT_PAGE_SIZE,
  minRows,
  columns = [{}],
  hideColumns = [],
  ...props
}) => {
  const filteredColumns = [
    {
      ...columns[0],
      columns: columns[0].columns.filter((col) => {
        const id = col.id || col.accessor;
        return !hideColumns.includes(id);
      }),
    },
  ];

  return (
    <ReactTable
      className={classNames('-striped', '-highlight', classes.root, className)}
      data={data}
      pageSize={pageSize}
      minRows={minRows}
      showPageSizeOptions={false}
      showPagination={false}
      resizable={false}
      getTheadGroupProps={() => ({
        className: classNames(classes.thGroup, classes.title),
      })}
      getTheadProps={() => ({
        className: classNames(classes.thead, classes.title),
      })}
      getTheadThProps={() => ({ className: classes.th })}
      columns={filteredColumns}
      {...props}
    />
  );
};

export default withStyles(tableStyles)(Table);

const headerStyles = (theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: '"Space Mono", monospace',
  },
  leftBox: {},
  rightBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  arrowIconBox: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowIconBoxDisabled: {
    cursor: 'auto',
    color: '#f5f7fa',
  },
  arrowIcon: {
    fontSize: '1.8rem',
  },
});

export const Header = withStyles(headerStyles)(
  ({
    classes,
    title,
    data = [],
    setPageNumber,
    pageNumber,
    hasNext,
    loading,
    pageSize = DEFAULT_PAGE_SIZE,
  }) => {
    const firstIndex = pageNumber * pageSize + 1;
    const lastIndex = firstIndex + Math.min(pageSize, data.length) - 1;

    return (
      <div className={classes.header}>
        <div className={classes.th}>{title}</div>
        <div className={classes.rightBox}>
          <div
            className={classNames(
              classes.arrowIconBox,
              pageNumber <= 0 && classes.arrowIconBoxDisabled,
            )}
            onClick={() => {
              if (loading || pageNumber <= 0) return;
              setPageNumber(Math.max(0, pageNumber - 1));
            }}
          >
            <FaCaretLeft className={classes.arrowIcon} />
          </div>
          <div className={classes.countBox}>
            {loading || isNaN(firstIndex) ? (
              <ClipLoader size={'1rem'} />
            ) : (
              lastIndex !== 0 && firstIndex + ' - ' + lastIndex
            )}
          </div>
          <div
            className={classNames(
              classes.arrowIconBox,
              !hasNext && classes.arrowIconBoxDisabled,
            )}
            onClick={() => {
              if (loading || !hasNext) return;
              setPageNumber(pageNumber + 1);
            }}
          >
            <FaCaretRight className={classes.arrowIcon} />
          </div>
        </div>
      </div>
    );
  },
);
