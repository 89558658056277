import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MuiThemeProvider,
  createTheme as createMuiTheme,
  withStyles,
} from '@material-ui/core/styles';
import { WalletManagerProvider } from '@iexec/react-wallet-manager';
import 'hint.css';
import { multiWeb3 } from '../services/web3';
import NavBar from '../components/NavBar';
import ControlBar from '../components/ControlBar';
import Footer from '../components/Footer';
import Notifier from '../components/Notifier';
import Drawer from '../components/Drawer';
import SubgraphProvider from '../components/provider/SubgraphProvider';
import { getChainKey } from '../utils/chain';
import { SUPPORTED_CHAIN_KEYS, INFURA_PROJECT_ID } from '../config';
import HomePage from './HomePage';
import AppPage from './AppPage';
import DatasetPage from './DatasetPage';
import WorkerpoolPage from './WorkerpoolPage';
import TransactionPage from './TransactionPage';
import TaskPage from './TaskPage';
import DealPage from './DealPage';
import AddressPage from './AddressPage';
import SearchPage from './SearchPage';
import Web3ProviderModal from '../components/Web3ProviderModal';
import AccountManagerModal from '../components/AccountManagerModal';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    secondary: {
      light: '#D4DCEA',
      main: '#A2B5D2',
      dark: '#4F5C73',
      contrastText: '#647799',
    },
    background: {
      default: '#FFFFFF',
      paper: '#f5f7fa',
      red: '#EF5353',
      green: '#11B15E',
      grey: '#6C6C6C',
      main: (opacity = '0.3') => `rgba(250,233,0,${opacity})`,
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
      disabled: '#505050',
      link: '#3498db',
      focusLink: '#1d6fa5',
    },
  },
  overrides: {
    MuiTabs: {
      root: {
        backgroundColor: '#f5f7fa',
      },
      indicator: {
        height: '3px',
        backgroundColor: '#FAE900',
      },
    },
    MuiTab: {
      root: {
        color: '#000000',
        minWidth: '72px !important',
        textTransform: 'unset',
        fontSize: '1em !important',
        fontWeight: 'bold',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#383838',
        fontSize: '1em',
      },
    },
    MuiFormControl: {
      root: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        flex: '0 0 auto',
        margin: '0.7em 0px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#000000',
        display: 'flex',
        alignItems: 'center',
        flex: 1,
      },
    },
    MuiInput: {
      root: {
        color: '#000000',
        margin: 0,
        borderRadius: '1em',
        border: '1px solid #505050',
        minWidth: 0,
        minHeight: 0,
        flex: 2,
      },
      input: {
        padding: '0.2em 0.7em',
      },
      multiline: {
        '& div': {
          margin: '0.5em',
        },
      },
      inputMultiline: {
        padding: 0,
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true, // No more ripple, on the whole application 💣!
    },
    MuiInputLabel: {
      disableAnimation: true,
    },
  },
});

const appStyles = (theme) => ({
  // scrollbars
  '@global': {
    // chromium based
    '*::-webkit-scrollbar': {
      height: '10px',
      width: '10px',
    },
    '*::-webkit-scrollbar-track-piece': {
      backgroundColor: theme.palette.background.paper,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundClip: 'content-box',
      backgroundColor: theme.palette.primary.main,
      border: `3px solid ${theme.palette.background.paper}`,
      borderRadius: '5px',
    },
    // firefox
    '*': {
      scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,
      scrollbarWidth: 'thin',
    },
  },
  appGrid: {
    minHeight: '100%',
    display: 'grid',
    padding: '8px 0',
    gridGap: '0',
    gridTemplateRows: '4.5em auto 1fr auto',
    gridTemplateAreas: `'header'
      'filter'
      'main'
      'footer'`,
  },
  main: {
    gridArea: 'main',
  },
  filter: {
    gridArea: 'filter',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 20px 0 20px',
  },
});
const App = withStyles(appStyles)(({ classes, match }) => (
  <div className={classes.appGrid}>
    <Drawer />
    <Route component={NavBar} />
    <Route component={ControlBar} />
    <div className={classes.main}>
      <Route exact path={`${match.path}`} component={HomePage} />
      <Route path={`${match.path}/tx/:txHash`} component={TransactionPage} />
      <Route path={`${match.path}/app/:address`} component={AppPage} />
      <Route path={`${match.path}/dataset/:address`} component={DatasetPage} />
      <Route
        path={`${match.path}/workerpool/:address`}
        component={WorkerpoolPage}
      />
      <Route path={`${match.path}/task/:taskid`} component={TaskPage} />
      <Route path={`${match.path}/deal/:dealid`} component={DealPage} />
      <Route path={`${match.path}/address/:address`} component={AddressPage} />
      <Route path={`${match.path}/search/:search`} component={SearchPage} />
    </div>
    <Footer />
  </div>
));

const AppWrapper = connect(
  (state) => ({
    chainId: state.chain.chainId,
  }),
  null,
)((props) => {
  const { chainId } = props;
  const chainKey = getChainKey(chainId);
  const redirectChain =
    chainKey && SUPPORTED_CHAIN_KEYS.includes(chainKey)
      ? chainKey
      : SUPPORTED_CHAIN_KEYS[0];
  const availableChains = SUPPORTED_CHAIN_KEYS.join('|');
  return (
    <SubgraphProvider>
      <WalletManagerProvider multiWeb3={multiWeb3} infura={INFURA_PROJECT_ID}>
        <MuiThemeProvider theme={theme}>
          <Web3ProviderModal />
          <AccountManagerModal />
          <Notifier />
          <Switch>
            <Redirect exact strict from={'/:chain/'} to={'/:chain'} />
            <Route path={`/:chain(${availableChains})`} component={App} />
            <Redirect to={`/${redirectChain}`} />
          </Switch>
        </MuiThemeProvider>
      </WalletManagerProvider>
    </SubgraphProvider>
  );
});

export default AppWrapper;
