import React from 'react';
import { useSelector } from 'react-redux';
import {
  ApolloProvider,
  split,
  HttpLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { getChainKey } from '../../utils/chain';
import {
  SUPPORTED_CHAIN_KEYS,
  SUBGRAPH_HTTP_URL,
  SUBGRAPH_WS_URL,
} from '../../config';
import Debug from 'debug';

const debug = Debug('SubgraphProvider');

const clients = {};
SUPPORTED_CHAIN_KEYS.forEach((k) => {
  try {
    debug(`creating ${k} client`);
    const httpLink = new HttpLink({
      uri: SUBGRAPH_HTTP_URL[k],
    });

    const wsLink = new WebSocketLink({
      uri: SUBGRAPH_WS_URL[k],
      options: {
        reconnect: true,
      },
    });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#handling-pagination
    const paginableCache = {
      merge(existing, incoming, { args }) {
        const merged = existing ? existing.slice(0) : [];
        // Insert the incoming elements in the right places, according to args.
        const end = args.skip + Math.min(args.limit, incoming.length);
        for (let i = args.skip; i < end; ++i) {
          merged[i] = incoming[i - args.skip];
        }
        return merged;
      },
      read(existing, { args }) {
        // If we read the field before any data has been written to the
        // cache, this function will return undefined, which correctly
        // indicates that the field is missing.
        const page =
          existing && existing.slice(args.skip, args.skip + args.limit);
        // If we ask for a page outside the bounds of the existing array,
        // page.length will be 0, and we should return undefined instead of
        // the empty array.
        if (page && page.length > 0) {
          return page;
        }
      },
    };

    const client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache({
        typePolicies: {
          Account: {
            merge: true,
            fields: {
              apps: paginableCache,
              datasets: paginableCache,
              workerppols: paginableCache,
              contributions: paginableCache,
              dealRequester: paginableCache,
              dealBeneficiary: paginableCache,
              taskRequester: paginableCache,
            },
          },
          Deal: {
            merge: true,
            fields: {
              tasks: paginableCache,
            },
          },
        },
      }),
      defaultOptions: {
        query: {
          fetchPolicy: 'cache-first',
          returnPartialData: true,
          partialRefetch: true,
        },
        watchQuery: {
          fetchPolicy: 'cache-first',
          returnPartialData: true,
          partialRefetch: true,
        },
      },
    });
    debug(`created ${k} client`);
    clients[k] = client;
  } catch (e) {
    debug(`error creating ${k} client: ${e.message}`);
  }
});

const SubgraphProvider = ({ children }) => {
  const chainId = useSelector((state) => state.chain.chainId);
  const chainKey = getChainKey(chainId);
  return (
    <ApolloProvider client={clients[chainKey] || Object.values(clients)[0]}>
      {children}
    </ApolloProvider>
  );
};

export default SubgraphProvider;
