import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import moment from 'moment';
import Table, { Header } from './Table';
import { displayRatio } from '../../utils/display';
import StatusCell from './StatusCell';

export default function Tasks({
  title,
  loading,
  pageSize,
  minRows,
  page,
  setPage,
  hasNext,
  tasks,
  hideColumns = [],
}) {
  const match = useRouteMatch();
  const link = (props) => (
    <Link to={`/${match.params.chain}/task/${props.row.taskid}`}>
      {props.value}
    </Link>
  );
  const columns = [
    {
      Header: () => (
        <Header
          title={title || 'Latest tasks'}
          data={tasks}
          setPageNumber={setPage}
          pageNumber={page}
          pageSize={pageSize}
          hasNext={hasNext}
          loading={loading}
        />
      ),
      columns: [
        {
          id: 'idx',
          minWidth: displayRatio(20),
          maxWidth: displayRatio(50),
          Header: 'Idx',
          accessor: (e) => e?.index,
          Cell: link,
        },
        {
          id: 'taskid',
          minWidth: displayRatio(100),
          Header: 'Task',
          accessor: (e) => e?.taskid,
          Cell: link,
        },
        {
          id: 'finalDeadline',
          minWidth: displayRatio(105),
          maxWidth: displayRatio(110),
          Header: 'Deadline',
          accessor: (e) =>
            moment(new Date(e?.finalDeadline * 1000))
              .format('DD/MM h:mm a')
              .toString(),
          Cell: link,
        },
        {
          id: 'status',
          width: displayRatio(90),
          Header: 'Status',
          accessor: (e) => (
            <StatusCell
              statusEnum={e?.status}
              timeoutTimestamp={e?.finalDeadline * 1000}
            />
          ),
          Cell: link,
        },
      ],
    },
  ];

  return (
    <Table
      loading={loading}
      pageNumber={page}
      data={tasks}
      columns={columns}
      pageSize={pageSize}
      minRows={minRows}
      hideColumns={hideColumns}
    />
  );
}
