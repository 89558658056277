import Debug from 'debug';
import { put, all, takeLatest, call } from 'redux-saga/effects';
import * as pricesActions from '../actions/prices';
import { sleep } from '../../utils/utils';

import { getEthPrice, getRlcPrice } from '../../services/assetsPrices';

const debug = Debug('sagas:prices');

export function* getAssetsPrices() {
  try {
    const ethPrice = yield call(getEthPrice);
    const rlcPrice = yield call(getRlcPrice);
    if (ethPrice && ethPrice.USD)
      yield put(pricesActions.setEthPrices(ethPrice));
    if (rlcPrice && rlcPrice.USD)
      yield put(pricesActions.setRlcPrices(rlcPrice));
  } catch (error) {
    debug('getAssetsPrices', error);
  }
  yield call(sleep, 30000);
  yield put(pricesActions.getAssetsPricesAsync());
}

// SAGAS WATCHERS
export function* watchPrices() {
  yield takeLatest('GET_ASSETS_PRICES', getAssetsPrices);
}

export default function* pricesSaga() {
  yield all([watchPrices()]);
}
