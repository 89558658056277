import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSubscription, gql } from '@apollo/client';
import { chainIdSelector } from '../../store/selectors/chain';
import Tasks from '../presentational/Tasks';

const PAGE_LENGTH = 20;

const TASKS_SUBSCRIPTION = gql`
  subscription Tasks($length: Int = 20, $skip: Int = 0) {
    tasks(
      first: $length
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
    ) {
      taskid: id
      index
      finalDeadline
      status
      timestamp
      deal {
        requester {
          address: id
        }
        beneficiary {
          address: id
        }
        app {
          address: id
        }
        dataset {
          address: id
        }
        workerpool {
          address: id
        }
        category {
          catid: id
          workClockTimeRef
        }
      }
    }
  }
`;

const TASKS_HAS_NEXT_SUBSCRIPTION = gql`
  subscription NextTask($next: Int = 0) {
    next: tasks(
      first: 1
      skip: $next
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
    }
  }
`;

export default function TasksContainer() {
  const [page, setPage] = useState(0);
  const chainId = useSelector(chainIdSelector);

  useEffect(() => {
    setPage(0);
  }, [chainId]);

  const sub = useSubscription(TASKS_SUBSCRIPTION, {
    variables: { skip: PAGE_LENGTH * page, length: PAGE_LENGTH },
  });
  const subHasNext = useSubscription(TASKS_HAS_NEXT_SUBSCRIPTION, {
    variables: { next: PAGE_LENGTH * (page + 1) },
  });

  const loading = sub.loading || subHasNext.loading;

  const tasks = (!loading && sub?.data?.tasks) || [];
  const hasNext = !loading && subHasNext.data?.next?.length === 1;

  const passedProps = {
    pageSize: PAGE_LENGTH,
    loading,
    page,
    setPage,
    hasNext,
    tasks,
  };
  return <Tasks {...passedProps} hideColumns={['idx']} />;
}
