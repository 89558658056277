import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ensLookupAsync } from '../../store/actions/ens';

const useEnsLookup = (address) => {
  const lowerCaseAddress = address.toLowerCase();
  const dispatch = useDispatch();
  const chainId = useSelector((state) => state.chain.chainId);

  const lookupState = useSelector((state) => {
    const lookup = state?.ens?.lookup;
    return lookup && lookup[chainId] && lookup[chainId][address];
  }, shallowEqual);
  const pending = !!lookupState?.pending;
  const ens = lookupState?.ens;

  useEffect(() => {
    if (!pending && ens === undefined) {
      dispatch(ensLookupAsync.request({ address: lowerCaseAddress, chainId }));
    }
  }, [chainId, dispatch, ens, lowerCaseAddress, pending]);

  return {
    pending,
    ens,
  };
};

export default useEnsLookup;
