import React from 'react';
import TextNoWrap from './TextNoWrap';
import TxLink from './TxLink';
import TaskLink from './TaskLink';
import AddressLink from './AddressLink';
import Bytes from './Bytes';
import WorkerpoolLink from './WorkerpoolLink';

const TaskEvent = (props) => {
  const {
    eventName,
    type,
    taskid,
    txHash,
    worker,
    workerpool,
    hash,
    digest,
    consensus,
    // results, // may be big
    // resultsCallback, // may be big
    chainId,
  } = props;

  return (
    <>
      {(eventName || type) && <>{eventName || type} </>}
      {txHash && (
        <>
          <TextNoWrap>
            <TxLink txHash={txHash} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {taskid && (
        <>
          <TextNoWrap>
            {'task '}
            <TaskLink taskid={taskid} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {workerpool && (
        <>
          <TextNoWrap>
            {'workerpool '}
            <WorkerpoolLink address={workerpool} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {worker && (
        <>
          <TextNoWrap>
            {'worker '}
            <AddressLink address={worker} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {hash && (
        <>
          <TextNoWrap>
            {'contribution hash '}
            <Bytes>{hash}</Bytes>
          </TextNoWrap>{' '}
        </>
      )}
      {consensus && (
        <>
          <TextNoWrap>
            {'consensus '}
            <Bytes>{consensus}</Bytes>
          </TextNoWrap>{' '}
        </>
      )}
      {digest && (
        <>
          <TextNoWrap>
            {'result digest '}
            <Bytes>{digest}</Bytes>
          </TextNoWrap>{' '}
        </>
      )}
    </>
  );
};

export default TaskEvent;
