import React from 'react';
// import Debug from 'debug'
import RSelect from 'react-select';
import { withTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import Color from 'color';

// const debug = Debug('Select')
const customStyles = (theme) => ({
  option: (base, { isDisabled, isFocused }) => ({
    ...base,
    backgroundColor: isFocused
      ? theme.palette.secondary.light
      : theme.palette.background.paper,
    color: isFocused
      ? theme.palette.secondary.contrastText
      : theme.palette.text.default,
    cursor: isDisabled ? 'not-allowed' : 'default',
  }),
  control: (base, { isFocused }) => ({
    ...base,
    border: `1px solid ${
      isFocused
        ? theme.palette.secondary.main
        : Color(theme.palette.secondary.main).alpha(0.5)
    }`,
    backgroundColor: theme.palette.background.default,
    minHeight: 30,
    fontWeight: 'bold',
    height: 30,
    minWidth: '8rem',
    borderRadius: 100,
    boxShadow: 'none',
    ':hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      boxShadow: 'none',
    },
  }),
  singleValue: (base, state) => ({
    ...base,
    color: theme.palette.secondary.main,
  }),
  valueContainer: (base, state) => ({
    ...base,
    padding: '2px 6px',
    justifyContent: 'center',
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    height: 30,
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    visibility: 'hidden',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: 4,
    color: state.isDisabled
      ? theme.palette.text.disabled
      : theme.palette.secondary.main,
    ':hover': {
      color: state.isDisabled
        ? theme.palette.text.disabled
        : theme.palette.secondary.main,
    },
  }),
  menu: (base, state) => ({
    ...base,
    marginTop: 3,
    marginBottom: 0,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
});

const Select = ({
  classes,
  theme,
  options,
  value,
  onChange,
  label,
  multi = true,
  className,
  ...props
}) => (
  <RSelect
    {...props}
    openMenuOnFocus={true}
    hideSelectedOptions={true}
    name="form-field-name"
    className={classNames('Select', className)}
    classNamePrefix="select"
    value={value}
    onChange={onChange}
    styles={customStyles(theme)}
    options={options.map((opt) => ({
      value: opt.value,
      label: opt.label,
      clearableValue: false,
    }))}
  />
);

export default withTheme(Select);
