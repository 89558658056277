import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import DatasetLink from './DatasetLink';
import Details from './Details';
import AddressLink from './AddressLink';
import Loading from './Loading';
import NotFound from './NotFound';
import CopyText from './CopyText';
import Bytes from './Bytes';
import { multiaddrHexToHuman } from '../../utils/format';
import TransferEvent from './TransferEvent';
import ContentWrapper from './ContentWrapper';
import Deals from './Deals';

const styles = (theme) => ({
  spacer: {
    height: '2rem',
  },
});

const useStyles = makeStyles(styles);

const Dataset = (props) => {
  const { loading, dataset, chainId, datasetDeals } = props;

  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(dataset).length === 0) {
    return <NotFound />;
  }
  const mintEvent = Array.isArray(dataset?.transfers) && dataset?.transfers[0];

  const details = {
    ...(dataset.address && {
      Address: (
        <DatasetLink
          address={dataset.address}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    ...(dataset.name && { 'Dataset Name': dataset.name }),
    ...(dataset.owner && {
      Owner: <AddressLink address={dataset.owner} chainId={chainId} />,
    }),
    ...(mintEvent?.timestamp && {
      Created: (
        <>
          {moment(mintEvent.timestamp * 1000).fromNow()} (
          {moment(mintEvent.timestamp * 1000).toString()})
        </>
      ),
    }),
    ...(dataset.multiaddr && {
      Multiaddr: <CopyText>{multiaddrHexToHuman(dataset.multiaddr)}</CopyText>,
    }),
    ...(dataset.checksum && { Checksum: <Bytes>{dataset.checksum}</Bytes> }),
    ...(Array.isArray(dataset?.transfers) && {
      Transfers: dataset.transfers.map((transferEvent, i) => (
        <TransferEvent {...transferEvent} chainId={chainId} key={i} />
      )),
    }),
  };

  return (
    <ContentWrapper>
      <Details title="Dataset details" details={details} />
      <div className={classes.spacer} />
      <Deals {...datasetDeals} hideColumns={['dataset']} />
    </ContentWrapper>
  );
};

export default Dataset;
