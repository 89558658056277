import React from 'react';
import TextNoWrap from './TextNoWrap';
import AddressLink from './AddressLink';
import TxLink from './TxLink';
import AppLink from './AppLink';
import DatasetLink from './DatasetLink';
import WorkerpoolLink from './WorkerpoolLink';

const TransferEvent = (props) => {
  const {
    eventName = 'Transfer',
    amount,
    app,
    dataset,
    workerpool,
    from,
    to,
    txHash,
    chainId,
  } = props;
  return (
    <>
      {eventName && <>{eventName} </>}
      {txHash && (
        <>
          <TextNoWrap>
            <TxLink txHash={txHash} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {amount && (
        <>
          <TextNoWrap>{amount}</TextNoWrap>{' '}
        </>
      )}
      {app && (
        <>
          <TextNoWrap>
            {'app '}
            <AppLink address={app} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {dataset && (
        <>
          <TextNoWrap>
            {'dataset '}
            <DatasetLink address={dataset} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {workerpool && (
        <>
          <TextNoWrap>
            {'workerpool '}
            <WorkerpoolLink address={workerpool} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      <TextNoWrap>
        {'from '}
        <AddressLink address={from} chainId={chainId} />
      </TextNoWrap>{' '}
      <TextNoWrap>
        {'to '}
        <AddressLink address={to} chainId={chainId} />
      </TextNoWrap>
    </>
  );
};

export default TransferEvent;
