import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  setShowProviderModal,
  setShowModal,
} from '../store/actions/accountManager';
import rlcLogo from '../assets/images/rlc.svg';
import { useWallet } from '@iexec/react-wallet-manager';
import { CustomRoundButton } from './Buttons';

const styles = (theme) => ({
  rlcOrSpinner: {
    marginLeft: '-0.5em',
    marginRight: '0.5em',
    height: 'auto',
    width: '1.8em',
    color: theme.palette.primary.contrastText,
  },
});

const Login = ({ classes, showAccountManager, login }) => {
  const { provider, connected } = useWallet();

  const connecting = !!provider && !connected;

  return (
    <Fragment>
      {connected && (
        <CustomRoundButton
          variant="contained"
          disableElevation
          color="primary"
          disabled={false}
          onClick={showAccountManager}
        >
          <img
            className={classes.rlcOrSpinner}
            src={rlcLogo}
            alt="iExec RLC logo"
          />
          ACCOUNT
        </CustomRoundButton>
      )}
      {!connected && connecting && (
        <CustomRoundButton
          variant="contained"
          disableElevation
          color="primary"
          disabled={false}
        >
          <CircularProgress className={classes.rlcOrSpinner} size="1.5em" />
          ACCOUNT
        </CustomRoundButton>
      )}
      {!connected && !connecting && (
        <CustomRoundButton
          variant="contained"
          disableElevation
          color="primary"
          onClick={login}
        >
          LOGIN
        </CustomRoundButton>
      )}
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  showAccountManager: () => dispatch(setShowModal(true)),
  login: () => dispatch(setShowProviderModal(true)),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(Login));
