import React, { Component } from 'react';
import Debug from 'debug';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { MdSearch as SearchIcon } from 'react-icons/md';
import { withStyles } from '@material-ui/core/styles';
import { mediaQueries } from '../utils/display';
import Input from '@material-ui/core/Input';

const debug = Debug('Searcher');

const styles = (theme) => ({
  root: {
    '-webkit-border-radius': 100,
    '-moz-border-radius': 100,
    borderRadius: 100,
    border: '1px solid',
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
    display: 'flex',
    [mediaQueries.medium.up]: {
      alignSelf: 'stretch',
      width: '100%',
    },
    '&:hover': {
      borderColor: theme.palette.secondary.main,
    },
    height: '30px',
  },
  input: {
    '-webkit-border-radius': 100,
    '-moz-border-radius': 100,
    backgroundColor: 'transparent',
    borderRadius: 100,
    border: 'none',
    display: 'flex',
    flexGrow: 1,
    color: theme.palette.secondary.contrastText,
    marginLeft: 10,
  },
  button: {
    backgroundColor: 'transparent',
    '-webkit-border-radius': 100,
    '-moz-border-radius': 100,
    color: theme.palette.secondary.contrastText,
    borderRadius: 100,
    border: 'none',
    width: 40,
    display: 'flex',
    '&>svg': {
      margin: 'auto',
    },
  },
});

let Searcher = withStyles(styles)(({ classes, handleSubmit, onChange }) => (
  <form
    className={classes.root}
    onSubmit={(event) => {
      debug('preventDefault');
      event.preventDefault();
      handleSubmit();
    }}
  >
    <Input
      className={classes.input}
      onChange={(e) => onChange(e.target.value)}
      component="input"
      type="text"
      placeholder="Search address or id or txHash"
    />
    <button type="submit" className={classes.button}>
      <SearchIcon size={20} />
    </button>
  </form>
));

class SearcherForm extends Component {
  constructor() {
    super();
    this.state = {
      txHashOrAddress: '',
    };
  }
  render() {
    debug('this.state.txHashOrAddress', this.state.txHashOrAddress);
    debug(
      `/${this.props.match.params.chain}/search/${this.state.txHashOrAddress}`,
    );
    return (
      <div>
        <Searcher
          onChange={(value) => this.setState({ txHashOrAddress: value })}
          handleSubmit={() =>
            this.props.redirect(
              `/${this.props.match.params.chain}/search/${this.state.txHashOrAddress}`,
            )
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  redirect: (url) => dispatch(push(url)),
});

export default connect(null, mapDispatchToProps)(withRouter(SearcherForm));
