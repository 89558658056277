import { combineReducers } from 'redux';

function rlcPrices(state = {}, action) {
  switch (action.type) {
    case 'SET_RLC_PRICES':
      return action.prices;
    default:
      return state;
  }
}

function ethPrices(state = {}, action) {
  switch (action.type) {
    case 'SET_ETH_PRICES':
      return action.prices;
    default:
      return state;
  }
}

export default combineReducers({
  rlcPrices,
  ethPrices,
});
