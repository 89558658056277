import CopyButton from './CopyButton';
import TextNoWrap from './TextNoWrap';

const CopyText = (props) => {
  const { children: text } = props;
  return (
    <TextNoWrap>
      {text}
      <CopyButton toClipboard={text} />
    </TextNoWrap>
  );
};

export default CopyText;
