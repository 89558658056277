import React from 'react';
import moment from 'moment';
import TaskLink from './TaskLink';
import DealLink from './DealLink';
import AppLink from './AppLink';
import DatasetLink from './DatasetLink';
import WorkerpoolLink from './WorkerpoolLink';
import Details from './Details';
import AddressLink from './AddressLink';
import Loading from './Loading';
import NotFound from './NotFound';
import Bytes from './Bytes';
import TaskEvent from './TaskEvent';
import TextNoWrap from './TextNoWrap';
import JsonBlock from './JsonBlock';
import StatusCell from './StatusCell';
import ActionLink from './ActionLink';
import { taskResultToHuman, taskResultToObject } from '../../utils/format';
import ContentWrapper from './ContentWrapper';

const Task = (props) => {
  const { loading, task, chainId, isConnected, onClaim, onDownload } = props;
  if (loading) {
    return <Loading />;
  }
  if (Object.keys(task).length === 0) {
    return <NotFound />;
  }

  const details = {
    ...(task.taskid && {
      Taskid: (
        <TaskLink
          taskid={task.taskid}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    ...(task.dealid && {
      Deal: <DealLink dealid={task.dealid} chainId={chainId} />,
    }),
    ...(task.catid !== undefined &&
      task.workClockTimeRef !== undefined && {
        Category: (
          <>
            {task.catid} ({task.workClockTimeRef * 10} sec)
          </>
        ),
      }),
    ...(task.tag && {
      Tag: <Bytes>{task.tag}</Bytes>,
    }),
    ...(task.app &&
      task.appName && {
        App: (
          <TextNoWrap>
            {task.appName} <AppLink address={task.app} chainId={chainId} />
          </TextNoWrap>
        ),
      }),
    ...(task.dataset &&
      task.datasetName && {
        Dataset: (
          <TextNoWrap>
            {task.datasetName}{' '}
            <DatasetLink address={task.dataset} chainId={chainId} />
          </TextNoWrap>
        ),
      }),
    ...(task.workerpool &&
      task.workerpoolDescription && {
        Workerpool: (
          <TextNoWrap>
            {task.workerpoolDescription}{' '}
            <WorkerpoolLink address={task.workerpool} chainId={chainId} />
          </TextNoWrap>
        ),
      }),
    ...(task.requester && {
      Requester: <AddressLink address={task.requester} chainId={chainId} />,
    }),
    ...(task.beneficiary && {
      Beneficiary: <AddressLink address={task.beneficiary} chainId={chainId} />,
    }),
    ...(task.callback && {
      'Callback contract': (
        <AddressLink address={task.callback} chainId={chainId} />
      ),
    }),
    ...(task.status && {
      Status: (
        <>
          <StatusCell statusEnum={task.status} />
          {task.finalDeadline &&
            task.finalDeadline * 1000 < Date.now() &&
            task.status !== 'COMPLETED' &&
            task.status !== 'FAILLED' &&
            isConnected && (
              <>
                <ActionLink action={onClaim}>Claim</ActionLink>
              </>
            )}
          {task.results &&
            taskResultToObject(task.results)?.storage === 'ipfs' && (
              <ActionLink action={onDownload}>
                Download result archive
              </ActionLink>
            )}
        </>
      ),
    }),
    ...(task.results && {
      Result: <JsonBlock>{taskResultToHuman(task.results)}</JsonBlock>,
    }),
    ...(task.resultsCallback && {
      'Callback data': <Bytes>{task.resultsCallback}</Bytes>,
    }),
    ...(task.resultDigest && {
      'Result digest': <Bytes>{task.resultDigest}</Bytes>,
    }),
    ...(task.finalDeadline && {
      'Final deadline': (
        <>
          {moment(task.finalDeadline * 1000).fromNow()} (
          {moment(task.finalDeadline * 1000).toString()})
        </>
      ),
    }),
    ...(task.contributionDeadline && {
      'Contribution deadline': (
        <>
          {moment(task.contributionDeadline * 1000).fromNow()} (
          {moment(task.contributionDeadline * 1000).toString()})
        </>
      ),
    }),
    ...(Array.isArray(task?.taskEvents) && {
      Events: task.taskEvents.map((taskEvent, i) => (
        <TaskEvent {...taskEvent} chainId={chainId} key={i} />
      )),
    }),
  };

  return (
    <ContentWrapper>
      <Details title="Task details" details={details} />
    </ContentWrapper>
  );
};

export default Task;
