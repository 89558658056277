import { Link } from 'react-router-dom';
import { getChainKey } from '../../utils/chain';
import classNames from 'classnames';
import CopyButton from './CopyButton';
import TextNoWrap from './TextNoWrap';

const TaskLink = (props) => {
  const { taskid, chainId, hideInternalLink = false } = props;
  const chainKey = getChainKey(chainId);
  return (
    <TextNoWrap>
      {hideInternalLink || !chainKey ? (
        <>{taskid}</>
      ) : (
        <Link
          className={classNames('hint--right', 'internal')}
          aria-label="view task details"
          to={`/${chainKey}/task/${taskid}`}
        >
          {taskid}
        </Link>
      )}
      <CopyButton toClipboard={taskid} />
    </TextNoWrap>
  );
};

export default TaskLink;
