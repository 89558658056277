import { SUPPORTED_CHAIN_KEYS, BLOCKCHAIN_EXPLORER_BASE_URL } from '../config';

export const chains = {
  mainnet: {
    name: 'mainnet',
    id: 1,
    isNative: false,
    desc: 'Ethereum Mainnet',
    bridgedChain: 'bellecour',
  },
  bellecour: {
    name: 'bellecour',
    id: 134,
    isNative: true,
    desc: 'iExec Sidechain',
    bridgedChain: 'mainnet',
  },
};
chains['1'] = chains.mainnet;
chains['134'] = chains.bellecour;

const chainKeys = {
  1: 'mainnet',
  134: 'bellecour',
};

export const chainIds = SUPPORTED_CHAIN_KEYS.map((name) =>
  parseInt(chains[name].id),
).filter((item, i, arr) => arr.indexOf(item) === i);

export const chainNames = chainIds.map((id) => chains[id].name);

export const isSupportedChain = (chainKey) =>
  !!chains[chainKey] && SUPPORTED_CHAIN_KEYS.includes(chains[chainKey].name);

export const isNativeChain = (chainKey) =>
  chains[chainKey] && !!chains[chainKey].isNative;

export const isBridged = (chainKey) =>
  chains[chainKey] && !!chains[chainKey].bridgedChain;

export const getChainKey = (chainId) => chainKeys[chainId];

export const getChainName = (chainId) =>
  (getChainKey(chainId) && chains[getChainKey(chainId)].desc) ||
  'unknown chain';

export const getBridgedChainName = (chainId) => {
  const connectedChainKey = getChainKey(chainId);
  return (
    (connectedChainKey &&
      chains[connectedChainKey].bridgedChain &&
      chains[chains[connectedChainKey].bridgedChain] &&
      chains[chains[connectedChainKey].bridgedChain].desc) ||
    'unknown chain'
  );
};

export const getBlockExplorerUrl = (chainId) => {
  const chainKey = getChainKey(chainId);
  return chainKey && BLOCKCHAIN_EXPLORER_BASE_URL[chainKey];
};

export const UNSUPPORTED_CHAIN = 'Unsupported chain';
