export const changeChain = (chainName) => ({ type: 'CHANGE_CHAIN', chainName });

export const setCurrentChain = (chainId) => ({
  type: 'SET_CURRENT_CHAIN_ID',
  chainId,
});

export const resetChain = () => ({
  type: 'RESET_CHAIN',
});
