import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useWalletManager, providerNames } from '@iexec/react-wallet-manager';
import {
  setShowProviderModal,
  setShowModal as setShowAccountManagerModal,
} from '../store/actions/accountManager';
import * as notifierActions from '../store/actions/notifier';
import {
  MuiThemeProvider,
  createTheme as createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import classNames from 'classnames';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { FaCheckCircle, FaTimes as Close } from 'react-icons/fa';
import { mediaQueries } from '../utils/display';
import metamaskIcon from '../assets/images/metamask_icon.svg';
import portisIcon from '../assets/images/portis_icon.svg';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Overpass-Regular, sans-serif',
      'Montserrat-Regular, sans-serif',
    ].join(','),
    subtitle1: {
      fontFamily: 'Montserrat-Regular, sans-serif',
    },
    fontSize: 16,
    color: '#304058',
  },
  palette: {
    primary: {
      light: '#FAE900',
      main: '#F9C300',
      dark: '#EABA0A',
      contrastText: '#5D4B00',
    },
    background: {
      paper: '#F5F5F5',
    },
    text: {
      primary: '#304058',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 'bold',
        textTransform: 'none',
      },
    },
  },
});

const styles = () => ({
  paper: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
    padding: '2em',
    overflowY: 'auto',
    fontSize: '1.2em',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    [mediaQueries.medium.up]: {
      width: 'auto',
      minWidth: '60vw',
      maxWidth: '90vw',
      height: 'auto',
      minHeight: '78vh',
      maxHeight: '90vh',
    },
  },
  title: {
    textAlign: 'center',
    padding: '0 2em',
    flex: 1,
  },
  closeButton: {
    cursor: 'pointer',
    display: 'block',
    marginLeft: 'auto',
    width: '2em',
    marginTop: '-1em',
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  providers: {
    display: 'block',
    [mediaQueries.medium.up]: {
      display: 'flex',
      flexDirection: 'row',
    },
  },
  provider: {
    flex: 1,
    textAlign: 'center',
    margin: '3em',
  },
  providerCard: {
    cursor: 'pointer',
    margin: '20px auto',
    backgroundColor: 'white',
    boxShadow: '0 2px 4px 0 #4F5C73',
    width: '200px',
    height: '200px',
    padding: '10px',
    '& img': {
      maxWidth: '180px',
      maxHeight: '180px',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    '&:hover': {
      '& img': {
        opacity: '1',
      },
    },
  },
  inactive: {
    '& img': {
      opacity: '0.5',
    },
    color: '#4F5C73',
    '& div': {
      boxShadow: 'none !important',
    },
  },
  button: {
    margin: '0 auto',
    marginTop: '2em',
  },
  content: {
    margin: '2em auto',
    textAlign: 'center',
  },
  checkIcon: {
    flex: 1,
    minHeight: '10vh',
    maxHeight: '20vh',
  },
  help: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    margin: '2em auto',
    '& h2': {
      marginBottom: '1em',
    },
    '& p': {
      marginBottom: '0.5em',
    },
  },
  spacer: {
    flex: 1,
  },
});

const useStyles = makeStyles(styles);

const Web3ProviderModal = (props) => {
  const { showModal, notify, closeModal, openAccountManager, chainId } = props;
  const [active, setActive] = useState('');

  // close modal on back
  const { listen: historyListen, push: historyPush } = useHistory();
  useEffect(() => {
    const unlisten = historyListen((location, action) => {
      if (action === 'POP' && showModal) {
        closeModal();
      }
    });
    return unlisten;
  }, [closeModal, historyListen, showModal]);
  useEffect(() => {
    if (showModal) {
      historyPush('#');
    }
  }, [historyPush, showModal]);

  const classes = useStyles();

  const { availableProviders, provider, connected, connectProvider } =
    useWalletManager();

  const connecting = !!provider && !connected;

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Paper className={classes.paper}>
        <div
          onClick={closeModal}
          aria-label={'Close'}
          className={classNames(classes.closeButton, 'hint--left')}
        >
          <Close size="2em" color={'#a2b5d2'} />
        </div>
        <div className={classes.spacer} />
        {!connected && (
          <>
            <h1 className={classes.title}>
              Please login with an Ethereum wallet
            </h1>
            <div className={classes.container}>
              <div className={classes.providers}>
                <div
                  className={classNames(
                    classes.provider,
                    active !== providerNames.METAMASK && classes.inactive,
                  )}
                >
                  {' '}
                  <div>
                    Already familiar with blockchain, tokens, and wallets?
                  </div>
                  <div
                    onClick={() => setActive(providerNames.METAMASK)}
                    className={classes.providerCard}
                  >
                    <img src={metamaskIcon} alt="MetaMask"></img>
                  </div>
                  <h2>MetaMask</h2>
                  {active === providerNames.METAMASK && (
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        connectProvider(providerNames.METAMASK, {
                          chainId,
                        }).catch((e) =>
                          notify({ level: 'error', message: e.message }),
                        )
                      }
                      disabled={
                        !availableProviders.includes(providerNames.METAMASK) ||
                        connecting
                      }
                    >
                      Login with MetaMask
                    </Button>
                  )}
                </div>
                <div
                  className={classNames(
                    classes.provider,
                    active !== providerNames.PORTIS && classes.inactive,
                  )}
                >
                  <div>Never used a blockchain wallet?</div>
                  <div
                    onClick={() => setActive(providerNames.PORTIS)}
                    className={classes.providerCard}
                  >
                    <img src={portisIcon} alt="Portis"></img>
                  </div>
                  <h2>Portis</h2>
                  {active === providerNames.PORTIS && (
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        connectProvider(providerNames.PORTIS, {
                          chainId,
                        }).catch((e) =>
                          notify({ level: 'error', message: e.message }),
                        )
                      }
                      disabled={
                        !availableProviders.includes(providerNames.PORTIS) ||
                        connecting
                      }
                    >
                      Login with Portis
                    </Button>
                  )}
                </div>
              </div>
              <div className={classes.spacer} />
              <div className={classes.help}>
                {!active && (
                  <>
                    <h2>Not sure how to get started?</h2>
                    <p>We will walk you through setting up a secure wallet.</p>
                    <div className={classes.spacer} />
                    <p>
                      <b>Select a wallet provider</b>
                    </p>
                  </>
                )}
                {active === providerNames.METAMASK && (
                  <>
                    <h2>About MetaMask</h2>
                    <p>
                      MetaMask allows you to interact with the blockchain
                      through a browser plugin and password.
                    </p>
                    <p>Your wallet is a browser plugin using a password.</p>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      onClick={() => {
                        window.open('https://metamask.io/', '_blank');
                      }}
                      className={classes.button}
                    >
                      {!availableProviders.includes(providerNames.METAMASK)
                        ? 'Get MetaMask'
                        : 'Read more'}
                    </Button>
                    {!availableProviders.includes(providerNames.METAMASK) && (
                      <>
                        <div className={classes.spacer} />
                        <div>(You may need to refresh the page)</div>
                      </>
                    )}
                  </>
                )}
                {active === providerNames.PORTIS && (
                  <>
                    <h2>About Portis</h2>
                    <p>
                      Portis allows you to access your wallet with a login and
                      password.
                    </p>
                    <p>
                      Your wallet is encrypted and stored on the Portis server.
                    </p>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      onClick={() => {
                        window.open('https://www.portis.io/', '_blank');
                      }}
                      className={classes.button}
                    >
                      Read more
                    </Button>
                  </>
                )}
                <div className={classes.spacer} />
              </div>
            </div>
          </>
        )}
        {connected && (
          <>
            <h1 className={classes.title}>
              You are now connected to the iExec platform!
            </h1>
            <div className={classes.container}>
              <div className={classes.spacer} />
              <FaCheckCircle
                className={classNames(classes.content, classes.checkIcon)}
                size={'200px'}
                color={'#11B15E'}
              />
              <div className={classes.spacer} />
              <div className={classes.content}>
                <p>
                  Your wallet is your identity on the blockchain. It
                  authenticates what you do and what you own.
                </p>
                <p>Make sure to keep your details safe.</p>
              </div>
              <div className={classes.spacer} />
              <Button
                variant="contained"
                disableElevation
                color="primary"
                className={classes.content}
                onClick={() => {
                  closeModal();
                  if (openAccountManager) openAccountManager();
                }}
              >
                Get started!
              </Button>
            </div>
          </>
        )}
        <div className={classes.spacer} />
      </Paper>
    </Modal>
  );
};

const ThemedWeb3ProviderModal = (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Web3ProviderModal {...props} />
    </MuiThemeProvider>
  );
};

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(setShowProviderModal(false)),
  openAccountManager: () => dispatch(setShowAccountManagerModal(true)),
  notify: (params) => dispatch(notifierActions.notify(params)),
});

const mapStateToProps = (state) => ({
  showModal: state.accountManager.showProviderModal,
  chainId: state.chain.chainId,
});

const Web3ProviderModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ThemedWeb3ProviderModal);

export default Web3ProviderModalConnected;
