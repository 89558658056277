import { combineReducers } from 'redux';

function lookup(state = {}, action) {
  switch (action.type) {
    case 'ENS_LOOKUP_SUCCESS': {
      const { address, ens, chainId } = action;
      return {
        ...state,
        [chainId]: {
          ...(state && state[chainId]),
          [address]: {
            pending: false,
            ens,
          },
        },
      };
    }
    case 'ENS_LOOKUP_REQUEST': {
      const { address, chainId } = action;
      return {
        ...state,
        [chainId]: {
          ...(state && state[chainId]),
          [address]: {
            pending: true,
            ens: undefined,
          },
        },
      };
    }
    case 'ENS_LOOKUP_FAILURE': {
      const { address, chainId } = action;
      return {
        ...state,
        [chainId]: {
          ...(state && state[chainId]),
          [address]: {
            ...(state && state[chainId] && state && state[chainId][address]),
            pending: false,
          },
        },
      };
    }
    default:
      return state;
  }
}

export default combineReducers({
  lookup,
});
