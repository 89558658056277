import useEnsLookup from '../provider/useEnsLookup';

const EnsAddress = (props) => {
  const { address } = props;
  const { ens } = useEnsLookup(address);
  return (
    <>
      {address}
      {ens && ` (${ens})`}
    </>
  );
};

export default EnsAddress;
