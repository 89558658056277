const ActionLink = (props) => {
  const { children, action } = props;
  return (
    <span
      style={{
        textDecoration: 'underline',
        cursor: 'pointer',
        margin: '0 1rem',
      }}
      onClick={action}
    >
      {children}
    </span>
  );
};

export default ActionLink;
