import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

export const CustomRoundButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    borderRadius: 100,
    fontWeight: 'bold',
    boxShadow: 'none !important',
  },
}))(Button);

export const CustomButton = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
}))(Button);
