import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { useWalletManager } from '@iexec/react-wallet-manager';
import Searcher from './Searcher';
import Select from './Select';
import { CustomRoundButton } from './Buttons';
import { changeChain } from '../store/actions/chain';
import { mediaQueries } from '../utils/display';
import {
  chains,
  chainNames,
  isSupportedChain,
  UNSUPPORTED_CHAIN,
} from '../utils/chain';

const styles = (theme) => ({
  root: {
    margin: '0 10px',
    gridArea: 'filter',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    [mediaQueries.medium.up]: {
      flexDirection: 'row-reverse',
      alignItems: 'center',
    },
  },
  controlContainerGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    '&>div': {
      margin: '8px',
    },
  },
  searchControlsGroup: {
    width: '100%',
    justifyContent: 'flex-start',
    [mediaQueries.medium.up]: {
      flex: 1,
    },
  },
  searcherRow: {
    flex: 1,
    [mediaQueries.medium.up]: {
      flex: '0 1 660px',
    },
  },
  select: {
    width: '16em',
  },
  button: {
    width: '16em',
  },
});

const ControlBar = ({ classes, history, match, chainId, changeChain }) => {
  const {
    connected,
    address,
    chainId: walletChainId,
    changeNetwork,
  } = useWalletManager();

  // update chain state on wallet change
  useEffect(() => {
    if (walletChainId && chainId !== walletChainId) {
      changeChain(walletChainId);
    }
  }, [changeChain, chainId, walletChainId]);

  const connectedChain = walletChainId || chainId;

  const chainName = isSupportedChain(connectedChain)
    ? chains[connectedChain] && chains[connectedChain].name
    : UNSUPPORTED_CHAIN;

  const getDisplayName = (name) =>
    (chains[name] && (chains[name].shortDesc || chains[name].desc)) || name;

  const allChainNames =
    connected && !isSupportedChain(walletChainId)
      ? [UNSUPPORTED_CHAIN, ...chainNames]
      : chainNames;

  const selectChainNames = allChainNames.map((name) => ({
    value: name,
    label: getDisplayName(name),
  }));
  return (
    <div className={classes.root}>
      <div className={classes.controlContainerGroup}>
        <Select
          className={classes.select}
          options={selectChainNames}
          value={{
            value: chainName,
            label: getDisplayName(chainName),
          }}
          onChange={(evt) =>
            connected
              ? changeNetwork(chains[evt.value]?.id)
              : changeChain(evt.value)
          }
        />
      </div>

      <div
        className={classNames(
          classes.controlContainerGroup,
          classes.searchControlsGroup,
        )}
      >
        <div className={classes.searcherRow}>
          <Searcher className={classes.searcherRow} />
        </div>
        {connected && isSupportedChain(walletChainId) && (
          <div>
            <CustomRoundButton
              className={classes.button}
              variant="outlined"
              color="secondary"
              onClick={() => {
                history.push(`/${match.params.chain}/address/${address}`);
              }}
            >
              My Activity
            </CustomRoundButton>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  chainId: state.chain.chainId,
});

const mapDispatchToProps = (dispatch) => ({
  changeChain: (chainName) => dispatch(changeChain(chainName)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles)(ControlBar)));
