import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { mediaQueries } from '../utils/display';
import AppsContainer from '../components/container/AppsContainer';
import DatasetsContainer from '../components/container/DatasetsContainer';
import WorkerpoolsContainer from '../components/container/WorkerpoolsContainer';
import TasksContainer from '../components/container/TasksContainer';
import DealsContainer from '../components/container/DealsContainer';

const homePageStyles = (theme) => ({
  mainGrid: {
    width: '100%',
    maxWidth: '100%',
    padding: 15,
    display: 'grid',
    gridGap: 10,
    gridTemplateRows: 'auto',
    gridTemplateAreas: `'deals' 'tasks'
      'apps' 'datasets' 'pools'`,
    [mediaQueries.medium.up]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto auto auto',
      gridTemplateAreas: `'deals deals tasks'
            'apps apps apps'
            'datasets datasets datasets'
            'pools pools pools'`,
    },
    [mediaQueries.big.up]: {
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto auto auto',
      gridTemplateAreas: `'deals deals tasks'
            'apps datasets pools'`,
    },
  },
});

const useStyles = makeStyles(homePageStyles);

const HomePage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.mainGrid}>
      <div style={{ gridArea: 'apps' }}>
        <AppsContainer />
      </div>
      <div style={{ gridArea: 'datasets' }}>
        <DatasetsContainer />
      </div>
      <div style={{ gridArea: 'pools' }}>
        <WorkerpoolsContainer />
      </div>
      <div style={{ gridArea: 'tasks' }}>
        <TasksContainer />
      </div>
      <div style={{ gridArea: 'deals' }}>
        <DealsContainer />
      </div>
    </div>
  );
};

export default HomePage;
