import { multiaddr } from 'multiaddr';
import { NULL_BYTES } from './ethereum';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const descToExcerpt = (desc) => {
  return desc.slice(0, 147).concat('...');
};

export const toShortHash = (string) =>
  `${''.concat(string.substring(0, 8), '...', string.slice(-8))}`;

export const toURL = (str) => str.toLowerCase().split(' ').join('_');

export const mrenclaveHexToHuman = (hexString) => {
  if (hexString?.substr(0, 2) !== '0x') return hexString;
  const buffer = Buffer.from(hexString.substr(2), 'hex');
  return buffer.toString();
};

export const multiaddrHexToHuman = (hexString) => {
  if (hexString.substr(0, 2) !== '0x') return hexString;
  let res;
  const buffer = Buffer.from(hexString.substr(2), 'hex');
  try {
    res = multiaddr(new Uint8Array(buffer)).toString();
  } catch (error) {
    res = buffer.toString();
  }
  return res;
};

export const taskResultToObject = (results) => {
  let resultObj = { storage: 'none' };
  try {
    if (results !== NULL_BYTES) {
      resultObj = JSON.parse(
        Buffer.from(results.substr(2), 'hex').toString('utf8'),
      );
    }
  } catch (e) {
    // nothing to do
  }
  return resultObj;
};

export const taskResultToHuman = (results) => {
  return JSON.stringify(taskResultToObject(results));
};
