export const getAssetsPricesAsync = () => ({ type: 'GET_ASSETS_PRICES' });

export const setRlcPrices = (prices) => ({
  type: 'SET_RLC_PRICES',
  prices,
});

export const setEthPrices = (prices) => ({
  type: 'SET_ETH_PRICES',
  prices,
});
