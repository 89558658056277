import React, { Component } from 'react';
import Debug from 'debug';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FaBars as Bars } from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import Login from './Login';
import { startup, set } from '../store/actions/app';
import navBarLogo from '../assets/images/iexec-logo.svg';

const debug = Debug('NavBar');

const styles = (theme) => ({
  root: {
    'grid-area': 'header',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftBox: {
    display: 'flex',
  },
  logoBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '1em',
  },
  logo: {
    height: 'auto',
    width: '6em',
    marginRight: '0.6rem',
  },
  title: {
    fontSize: '1.3em',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
  rightBox: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    flex: 1,
  },
  bars: {
    cursor: 'pointer',
    fontSize: '1.5em',
    color: theme.palette.text.primary,
    padding: 20,
    lineHeight: 0,
  },
});

class NavBar extends Component {
  componentDidMount() {
    const { startup } = this.props;
    startup();
  }
  render() {
    const { match, location, toggleDrawer, classes } = this.props;
    const chainName = match.params.chain;
    debug('location', location);

    return (
      <div className={classes.root}>
        <div className={classes.leftBox}>
          <Link className={classes.logoBox} to={`/${chainName}`}>
            <img className={classes.logo} src={navBarLogo} alt="iExec logo" />
            <div className={classes.title}> Explorer </div>
          </Link>
        </div>
        <div className={classes.rightBox}>
          <Login />
          <div
            className={classes.bars}
            onClick={() => toggleDrawer()}
            onKeyDown={() => toggleDrawer()}
          >
            <Bars />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startup: () => dispatch(startup()),
  toggleDrawer: () => dispatch(set.toggleDrawer()),
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(NavBar));
