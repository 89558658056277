import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSubscription, gql } from '@apollo/client';
import { chainIdSelector } from '../../store/selectors/chain';
import Apps from '../presentational/Apps';

const PAGE_LENGTH = 10;

const APPS_SUBSCRIPTION = gql`
  subscription Apps($length: Int = 20, $skip: Int = 0) {
    apps(
      first: $length
      skip: $skip
      orderBy: timestamp
      orderDirection: desc
    ) {
      address: id
      owner {
        address: id
      }
      timestamp
      name
      type
      multiaddr
      checksum
      mrenclave
      transfers(orderBy: timestamp, orderDirection: desc) {
        transaction {
          txHash: id
          timestamp
          blockNumber
        }
      }
    }
  }
`;

const APPS_HAS_NEXT_SUBSCRIPTION = gql`
  subscription NextApp($next: Int = 0) {
    next: apps(
      first: 1
      skip: $next
      orderBy: timestamp
      orderDirection: desc
    ) {
      id
    }
  }
`;

export default function AppsContainer() {
  const [page, setPage] = useState(0);
  const chainId = useSelector(chainIdSelector);

  useEffect(() => {
    setPage(0);
  }, [chainId]);

  const sub = useSubscription(APPS_SUBSCRIPTION, {
    variables: { skip: PAGE_LENGTH * page, length: PAGE_LENGTH },
  });
  const subHasNext = useSubscription(APPS_HAS_NEXT_SUBSCRIPTION, {
    variables: { next: PAGE_LENGTH * (page + 1) },
  });

  const loading = sub.loading || subHasNext.loading;

  const apps = (!loading && sub?.data?.apps) || [];
  const hasNext = !loading && subHasNext.data?.next?.length === 1;

  const passedProps = {
    pageSize: PAGE_LENGTH,
    loading,
    page,
    setPage,
    hasNext,
    apps,
  };

  return <Apps {...passedProps} />;
}
