import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CopyButton from './CopyButton';
import { getChainKey } from '../../utils/chain';
import TextNoWrap from './TextNoWrap';

const DealLink = (props) => {
  const { chainId, dealid, hideInternalLink = false } = props;
  const chainKey = getChainKey(chainId);
  return (
    <TextNoWrap>
      {hideInternalLink || !chainKey ? (
        <>{dealid}</>
      ) : (
        <Link
          className={classNames('hint--right', 'internal')}
          aria-label="view deal details"
          to={`/${chainKey}/deal/${dealid}`}
        >
          {dealid}
        </Link>
      )}
      <CopyButton toClipboard={dealid} />
    </TextNoWrap>
  );
};

export default DealLink;
