import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import moment from 'moment';
import Table, { Header } from './Table';
import { displayRatio, mobileDisplay } from '../../utils/display';
import { rlcToNano, sumValues } from '../../utils/maths';
import RlcDisplay from '../RlcDisplay';
import SuccessCell from './SuccessCell';

export default function Deals({
  title,
  loading,
  pageSize,
  minRows,
  page,
  setPage,
  hasNext,
  deals,
  hideColumns = [],
}) {
  const match = useRouteMatch();
  const link = (props) => (
    <Link to={`/${match.params.chain}/deal/${props.row.dealid}`}>
      {props.value}
    </Link>
  );
  const columns = [
    {
      Header: () => (
        <Header
          title={title || 'Latest deals'}
          data={deals}
          setPageNumber={setPage}
          pageNumber={page}
          pageSize={pageSize}
          hasNext={hasNext}
          loading={loading}
        />
      ),
      columns: [
        {
          id: 'blockTimestamp',
          minWidth: displayRatio(95),
          maxWidth: displayRatio(120),
          Header: 'Time',
          accessor: (e) => moment(e?.timestamp * 1000).fromNow(),
          Cell: link,
        },
        {
          id: 'dealid',
          minWidth: displayRatio(100),
          Header: 'Deal',
          accessor: 'dealid',
          Cell: link,
        },
        {
          id: 'app',
          show: !mobileDisplay,
          minWidth: displayRatio(100),
          Header: 'App',
          accessor: (e) => e?.app?.address,
          Cell: link,
        },
        {
          id: 'workerpool',
          show: !mobileDisplay,
          minWidth: displayRatio(100),
          Header: 'Workerpool',
          accessor: (e) => e?.workerpool?.address,
          Cell: link,
        },
        {
          id: 'dataset',
          show: !mobileDisplay,
          minWidth: displayRatio(100),
          Header: 'Dataset',
          accessor: (e) => e?.dataset?.address,
          Cell: link,
        },
        {
          id: 'price',
          show: !mobileDisplay,
          width: displayRatio(125),
          Header: 'Price',
          accessor: (e) => (
            <RlcDisplay
              amount={rlcToNano(
                sumValues(
                  e?.appPrice || '0',
                  e?.datasePrice || '0',
                  e?.workerpoolPrice || '0',
                ),
              )}
            />
          ),
          Cell: link,
        },
        {
          id: 'completion',
          width: displayRatio(90),
          Header: 'Success',
          accessor: (deal) => {
            const dealDeadline =
              deal?.startTime &&
              deal?.category?.workClockTimeRef &&
              (parseInt(deal?.startTime) +
                parseInt(deal?.category?.workClockTimeRef) * 10) *
                1000;

            const tasksCount = parseInt(deal?.botSize) || 1;
            const completedTasksCount =
              parseInt(deal?.completedTasksCount) || 0;
            const completedRatio = completedTasksCount / tasksCount;
            return (
              <SuccessCell
                successRate={completedRatio}
                timeoutTimestamp={dealDeadline}
              />
            );
          },
          Cell: link,
        },
        // {
        //   width: displayRatio(60),
        //   Header: 'Vol.',
        //   id: 'volume',
        //   accessor: (e) => `${e?.botSize}`,
        //   Cell: link,
        // },
      ],
    },
  ];

  return (
    <Table
      loading={loading}
      pageNumber={page}
      data={deals}
      columns={columns}
      pageSize={pageSize}
      minRows={minRows}
      hideColumns={hideColumns}
    />
  );
}
