import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Big from 'big.js';

const makeDefaultStyle = makeStyles({
  root: {
    display: 'inline-flex',
  },
  unit: {
    paddingLeft: '0.3em',
  },
});

const AssetDisplay = ({
  amount,
  unit = 'ETH',
  displayUnit = true,
  displayFiat = false,
  exRate,
  loading,
  ne = -19,
  pe = 9,
  classes = {},
}) => {
  Big.NE = ne;
  Big.PE = pe;
  const bigAmount = new Big(amount.toString());
  const unitName = unit === 'wei' ? 'wei' : 'ETH';
  const unitMultiplier = unit === 'wei' ? new Big(1) : new Big(10).pow(-18);
  const value = bigAmount.times(unitMultiplier);
  const displayValue = value.toString();
  const displayFiatValue =
    displayFiat && exRate
      ? value.times(new Big(exRate.toString())).toFixed(2)
      : ' - ';

  const defaultClasses = makeDefaultStyle();

  if (loading) {
    return (
      <div className={classes.root || defaultClasses.root}>
        <div className={classes.value}>{' - '}</div>
        {displayUnit && (
          <div className={classes.unit || defaultClasses.unit}>{unitName}</div>
        )}
        {displayFiat && (
          <div className={classes.fiat || defaultClasses.fiat}>{'$ - '}</div>
        )}
      </div>
    );
  }

  return (
    <div className={classes.root || defaultClasses.root}>
      <div className={classes.value}>{displayValue}</div>
      {displayUnit && (
        <div className={classes.unit || defaultClasses.unit}>{unitName}</div>
      )}
      {displayFiat && (
        <div className={classes.fiat || defaultClasses.fiat}>
          ${displayFiatValue}
        </div>
      )}
    </div>
  );
};

export default AssetDisplay;
