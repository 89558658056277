import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import moment from 'moment';
import Table, { Header } from './Table';
import { displayRatio, mobileDisplay } from '../../utils/display';

export default function Datasets({
  title,
  loading,
  pageSize,
  minRows,
  page,
  setPage,
  hasNext,
  datasets,
  hideColumns = [],
}) {
  const match = useRouteMatch();
  const link = (props) => (
    <Link to={`/${match.params.chain}/dataset/${props.row.address}`}>
      {props.value}
    </Link>
  );
  const columns = [
    {
      Header: () => (
        <Header
          title={title || 'Latest datasets deployed'}
          data={datasets}
          setPageNumber={setPage}
          pageNumber={page}
          pageSize={pageSize}
          hasNext={hasNext}
          loading={loading}
        />
      ),
      columns: [
        {
          id: 'timestamp',
          minWidth: displayRatio(95),
          maxWidth: displayRatio(120),
          Header: 'Time',
          accessor: (e) => moment(e?.timestamp * 1000).fromNow(),
          Cell: link,
        },
        {
          id: 'transaction',
          show: !mobileDisplay,
          minWidth: displayRatio(100),
          Header: 'TxHash',
          accessor: (e) =>
            Array.isArray(e?.transfers) && e?.transfers[0]?.transaction?.txHash,
          Cell: link,
        },
        {
          id: 'address',
          minWidth: displayRatio(100),
          Header: 'Address',
          accessor: (e) => e?.address,
          Cell: link,
        },
        {
          id: 'owner',
          show: !mobileDisplay,
          minWidth: displayRatio(100),
          Header: 'Owner',
          accessor: (e) => e?.owner?.address,
          Cell: link,
        },
        {
          id: 'name',
          minWidth: displayRatio(80),
          maxWidth: displayRatio(150),
          Header: 'Name',
          accessor: (e) => e?.name,
          Cell: link,
        },
      ],
    },
  ];

  return (
    <Table
      loading={loading}
      pageNumber={page}
      data={datasets}
      columns={columns}
      pageSize={pageSize}
      minRows={minRows}
      hideColumns={hideColumns}
    />
  );
}
