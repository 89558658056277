import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddressLink from './AddressLink';
import Details from './Details';
import Loading from './Loading';
import NotFound from './NotFound';
import Apps from './Apps';
import Datasets from './Datasets';
import Workerpools from './Workerpools';
import Deals from './Deals';
import Tasks from './Tasks';
import Contributions from './Contributions';
import RlcDisplay from '../RlcDisplay';
import { rlcToNano } from '../../utils/maths';
import ContentWrapper from './ContentWrapper';

const styles = (theme) => ({
  spacer: {
    height: '1rem',
  },
  row: {
    display: 'flex',
    flex: 1,
    flexWrap: 'nowrap',
    margin: '5px 0px',
  },
  key: {
    display: 'flex',
    minWidth: 180,
  },
  value: {
    display: 'flex',
  },
  wrapper: {
    border: 'solid 2px #f5f7fa',
    padding: '10px',
  },
});

const useStyles = makeStyles(styles);

const Address = (props) => {
  const {
    loading,
    accountOverview,
    accountApps,
    accountDatasets,
    accountWorkerpools,
    accountContributions,
    accountRequesterDeals,
    accountRequesterTasks,
    accountBeneficiaryDeals,
    chainId,
  } = props;

  const [activeTab, setActiveTab] = useState('overview');
  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(accountOverview).length === 0) {
    return <NotFound />;
  }

  const addressDetails = {
    ...(accountOverview.address && {
      Address: (
        <AddressLink
          address={accountOverview.address}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    // TODO wallet balance
    ...(accountOverview.staked && {
      Staked: <RlcDisplay amount={rlcToNano(accountOverview.staked)} />,
    }),
    ...(accountOverview.locked && {
      Locked: <RlcDisplay amount={rlcToNano(accountOverview.locked)} />,
    }),
  };

  const overviewDetails = {
    ...(accountOverview.appsCount !== undefined && {
      Apps: accountOverview.appsCount,
    }),
    ...(accountOverview.datasetsCount !== undefined && {
      Datasets: accountOverview.datasetsCount,
    }),
    ...(accountOverview.workerpoolsCount !== undefined && {
      Workerpools: accountOverview.workerpoolsCount,
    }),
    ...(accountOverview.contributionsCount !== undefined && {
      Contributions: accountOverview.contributionsCount,
    }),
    ...(accountOverview.contributionsCount !== undefined &&
      accountOverview.score && {
        Score: accountOverview.score,
      }),
  };

  const hasRequesterTasks = accountRequesterTasks?.tasks?.length > 0;
  const hasRequesterDeals = accountRequesterDeals?.deals?.length > 0;
  const hasBeneficiaryDeals = accountBeneficiaryDeals?.deals?.length > 0;

  return (
    <ContentWrapper>
      <Details title="Address details" details={addressDetails} />
      {!loading && (
        <>
          <div className={classes.spacer} />
          <Tabs
            value={activeTab}
            onChange={(event, value) => setActiveTab(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="OVERVIEW" value="overview" />
            {(hasRequesterTasks ||
              hasRequesterDeals ||
              hasBeneficiaryDeals) && (
              <Tab label="REQUESTS" value="requester" />
            )}
            {parseInt(accountOverview?.contributionsCount) > 0 && (
              <Tab label="WORKER" value="worker" />
            )}
            {parseInt(accountOverview?.appsCount) > 0 && (
              <Tab label="APPS" value="apps" />
            )}
            {parseInt(accountOverview?.datasetsCount) > 0 && (
              <Tab label="DATASETS" value="datasets" />
            )}
            {parseInt(accountOverview?.workerpoolsCount) > 0 && (
              <Tab label="WORKERPOOLS" value="workerpools" />
            )}
          </Tabs>

          <div className={classes.wrapper}>
            {activeTab === 'overview' && <Details details={overviewDetails} />}
            {activeTab === 'requester' && (
              <>
                <Tasks
                  title="Requested tasks"
                  hideColumns={['transaction', 'idx']}
                  {...accountRequesterTasks}
                />
                <div className={classes.spacer} />
                <Deals
                  title="Requested deals"
                  hideColumns={['transaction']}
                  {...accountRequesterDeals}
                />
                <div className={classes.spacer} />
                <Deals
                  title="Beneficiary deals"
                  hideColumns={['transaction']}
                  {...accountBeneficiaryDeals}
                />
              </>
            )}
            {activeTab === 'apps' && (
              <>
                <Details
                  details={{
                    'Deployed apps': accountOverview.appsCount,
                  }}
                />
                <div className={classes.spacer} />
                <Apps
                  title="Apps"
                  hideColumns={['transaction', 'owner']}
                  {...accountApps}
                />
              </>
            )}
            {activeTab === 'datasets' && (
              <>
                <Details
                  details={{
                    'Deployed datasets': accountOverview.datasetsCount,
                  }}
                />
                <div className={classes.spacer} />
                <Datasets
                  title="Datasets"
                  hideColumns={['transaction', 'owner']}
                  {...accountDatasets}
                />
              </>
            )}
            {activeTab === 'workerpools' && (
              <>
                <Details
                  details={{
                    'Deployed workerpools': accountOverview.workerpoolsCount,
                  }}
                />
                <div className={classes.spacer} />
                <Workerpools
                  title="Workerpools"
                  hideColumns={['transaction', 'owner']}
                  {...accountWorkerpools}
                />
              </>
            )}
            {activeTab === 'worker' && (
              <>
                <Details
                  details={{
                    ...(accountOverview.contributionsCount && {
                      Contributions: accountOverview.contributionsCount,
                    }),
                    ...(accountOverview.contributionsCount &&
                      accountOverview.score && {
                        Score: accountOverview.score,
                      }),
                  }}
                />
                <div className={classes.spacer} />
                <Contributions
                  title="Contributions"
                  {...accountContributions}
                />
              </>
            )}
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

export default Address;
