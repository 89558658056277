import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      color: '#030725',
      '&.internal': {
        color: theme.palette.text.link,
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.text.focusLink,
        },
      },
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  row: {
    display: 'flex',
    flexWrap: 'nowrap',
    margin: '5px 0px',
    width: '100%',
  },
  key: {
    minWidth: 80,
    marginRight: 10,
  },
  value: {
    flex: 1,
    display: 'flex',
  },
  multilineValue: {
    display: 'inline-block',
  },
});

const useStyles = makeStyles(styles);

const Details = (props) => {
  const { title, details = {} } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {title && (
        <div className={classNames(classes.title, classes.row)}>{title}</div>
      )}
      {Object.entries(details).map(([key, value], i) => (
        <div className={classes.row} key={i}>
          <div className={classes.key}>{key} :</div>
          {Array.isArray(value) ? (
            <div className={classes.multilineValue}>
              {value.map((subValue, j) => (
                <div key={j}>{subValue}</div>
              ))}
            </div>
          ) : (
            <div className={classes.value}>{value}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Details;
