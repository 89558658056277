import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import AppLink from './AppLink';
import Details from './Details';
import AddressLink from './AddressLink';
import Loading from './Loading';
import NotFound from './NotFound';
import CopyText from './CopyText';
import Bytes from './Bytes';
import { isJson } from '../../utils/validation';
import { mrenclaveHexToHuman, multiaddrHexToHuman } from '../../utils/format';
import JsonBlock from './JsonBlock';
import TransferEvent from './TransferEvent';
import ContentWrapper from './ContentWrapper';
import Deals from './Deals';

const styles = (theme) => ({
  spacer: {
    height: '2rem',
  },
});

const useStyles = makeStyles(styles);

const App = (props) => {
  const { loading, app, chainId, appDeals } = props;

  const classes = useStyles();

  if (loading) {
    return <Loading />;
  }
  if (Object.keys(app).length === 0) {
    return <NotFound />;
  }
  const mintEvent = Array.isArray(app?.transfers) && app?.transfers[0];
  const mrenclave = mrenclaveHexToHuman(app?.mrenclave);

  const details = {
    ...(app.address && {
      Address: (
        <AppLink
          address={app.address}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    ...(app.name && { 'App Name': app.name }),
    ...(app.owner && {
      Owner: <AddressLink address={app.owner} chainId={chainId} />,
    }),
    ...(mintEvent?.timestamp && {
      Created: (
        <>
          {moment(mintEvent.timestamp * 1000).fromNow()} (
          {moment(mintEvent.timestamp * 1000).toString()})
        </>
      ),
    }),
    ...(app.multiaddr && {
      Multiaddr: <CopyText>{multiaddrHexToHuman(app.multiaddr)}</CopyText>,
    }),
    ...(app.checksum && { Checksum: <Bytes>{app.checksum}</Bytes> }),
    ...(mrenclave && {
      MrEnclave: isJson(mrenclave) ? (
        <JsonBlock>{mrenclave}</JsonBlock>
      ) : (
        <CopyText>{mrenclave}</CopyText>
      ),
    }),
    ...(Array.isArray(app?.transfers) && {
      Transfers: app.transfers.map((transferEvent, i) => (
        <TransferEvent {...transferEvent} chainId={chainId} key={i} />
      )),
    }),
  };

  return (
    <ContentWrapper>
      <Details title="App details" details={details} />
      <div className={classes.spacer} />
      <Deals {...appDeals} hideColumns={['app']} />
    </ContentWrapper>
  );
};

export default App;
