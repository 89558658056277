import React from 'react';
import copy from 'copy-to-clipboard';
import { FaCopy } from 'react-icons/fa';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as notifierActions from '../../store/actions/notifier';
import classNames from 'classnames';

const styles = (theme) => ({
  clipboard: {
    cursor: 'copy',
    marginLeft: '5px',
  },
});

const CopyButton = ({ toClipboard, notify, classes, className, ...props }) => (
  <div
    {...props}
    aria-label="click to copy"
    className={classNames(classes.clipboard, 'hint--right', className)}
    onClick={(e) => {
      copy(toClipboard);
      notify({ message: 'copied to clipboard' });
    }}
  >
    <FaCopy />
  </div>
);

const mapDispatchToProps = (dispatch) => ({
  notify: (params) => dispatch(notifierActions.notify(params)),
});

export default connect(
  null,
  mapDispatchToProps,
)(withStyles(styles)(CopyButton));
