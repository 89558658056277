import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const styles = (theme) => ({
  link: {
    cursor: 'copy',
    marginLeft: '5px',
  },
});

const LinkButton = ({ url, hint, classes, className, ...props }) => (
  <a
    {...props}
    className={classNames(classes.link, 'hint--right', className)}
    aria-label={hint}
    target="_blank"
    rel="noreferrer noopener"
    href={url}
  >
    <FaExternalLinkAlt />
  </a>
);

export default withStyles(styles)(LinkButton);
