import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { getBlockExplorerUrl, getChainKey } from '../../utils/chain';
import LinkButton from './LinkButton';
import CopyButton from './CopyButton';
import TextNoWrap from './TextNoWrap';
import EnsAddress from './EnsAddress';

const AddressLink = (props) => {
  const { address, chainId, hideInternalLink = false } = props;
  const chainKey = getChainKey(chainId);
  const blockExplorerUrl = getBlockExplorerUrl(chainId);
  return (
    <TextNoWrap>
      {' '}
      {hideInternalLink || !chainKey ? (
        <EnsAddress address={address} />
      ) : (
        <Link
          className={classNames('hint--right', 'internal')}
          aria-label="view address details"
          to={`/${chainKey}/address/${address}`}
        >
          <EnsAddress address={address} />
        </Link>
      )}
      {blockExplorerUrl && (
        <LinkButton
          url={`${blockExplorerUrl}/address/${address}`}
          hint="open in blockchain explorer"
        />
      )}
      <CopyButton toClipboard={address} />
    </TextNoWrap>
  );
};

export default AddressLink;
