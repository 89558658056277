import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery, gql } from '@apollo/client';
import { useHistory, useRouteMatch } from 'react-router';
import { sleep } from '../../utils/utils';
import { isAddress } from '@ethersproject/address';
import Search from '../presentational/Search';
import { useWalletManager } from '@iexec/react-wallet-manager';
import IExecENSModule from 'iexec/IExecENSModule';
import { INFURA_PROJECT_ID } from '../../config';
import { chainIdSelector } from '../../store/selectors/chain';

const SEARCH_QUERY = gql`
  query Search($search: ID!) {
    # objects
    app(id: $search) {
      address: id
    }
    dataset(id: $search) {
      address: id
    }
    workerpool(id: $search) {
      address: id
    }
    deal(id: $search) {
      dealid: id
    }
    task(id: $search) {
      taskid: id
    }
    account(id: $search) {
      address: id
    }
    # transaction
    transaction(id: $search) {
      txHash: id
      from {
        address: id
      }
      to {
        address: id
      }
      gasLimit
      gasUsed
      gasPrice
    }
  }
`;

const validateInput = (string) =>
  string.length === 42 || // address
  string.length === 66 || // bytes32
  string.endsWith('.eth'); // ENS

export default function SearchContainer(props) {
  const { search = '' } = props;
  const isValid = validateInput(search);

  const [resolvedSearch, setResolvedSearchSearch] = useState('');
  const [delaySearch, setDelaySearch] = useState(false);

  const match = useRouteMatch('/:chainName/');
  const history = useHistory();

  const { iexecConfig } = useWalletManager();
  const chainId = useSelector(chainIdSelector);

  useEffect(() => {
    if (isValid) {
      let abort = false;
      (async () => {
        let transiantSearch = search.toLowerCase();
        setDelaySearch(true);
        // delay
        await sleep(300);
        if (!abort) {
          // resolve ENS
          if (transiantSearch.endsWith('.eth')) {
            let ensModule;
            if (iexecConfig) {
              ensModule = IExecENSModule.fromConfig(iexecConfig);
            } else if (chainId) {
              ensModule = new IExecENSModule(
                {
                  ethProvider: chainId,
                },
                {
                  providerOptions: {
                    infura: INFURA_PROJECT_ID,
                  },
                },
              );
            }
            if (ensModule) {
              const address = await ensModule
                .resolveName(transiantSearch)
                .catch(() => {});
              if (address) {
                transiantSearch = address.toLocaleLowerCase();
              }
            }
          }
        }
        if (!abort) {
          setResolvedSearchSearch(transiantSearch);
          setDelaySearch(false);
        }
      })();
      return () => {
        abort = true;
      };
    }
  }, [search, iexecConfig, isValid, chainId]);

  const query = useQuery(SEARCH_QUERY, {
    variables: { search: resolvedSearch },
  });

  const loading = delaySearch || query.loading;
  const results = !loading ? query?.data : undefined;
  const error = !loading ? query?.error : undefined;

  let resultType;
  let resultObj;

  if (results) {
    if (results?.app) {
      resultType = 'app';
      resultObj = results?.app;
    } else if (results?.dataset) {
      resultType = 'dataset';
      resultObj = results?.dataset;
    } else if (results?.workerpool) {
      resultType = 'workerpool';
      resultObj = results?.workerpool;
    } else if (results?.deal) {
      resultType = 'deal';
      resultObj = results?.deal;
    } else if (results?.task) {
      resultType = 'task';
      resultObj = results?.task;
    } else if (results?.transaction) {
      resultType = 'transaction';
      resultObj = results?.transaction;
    } else if (results?.account) {
      resultType = 'account';
      resultObj = results?.account;
    } else if (isAddress(resolvedSearch)) {
      resultType = 'address';
      resultObj = {
        address: resolvedSearch,
      };
    }
  }

  useEffect(() => {
    if (resultType) {
      let targetPath;
      switch (resultType) {
        case 'app':
          targetPath = `/${match.params.chainName}/app/${resultObj.address}`;
          break;
        case 'dataset':
          targetPath = `/${match.params.chainName}/dataset/${resultObj.address}`;
          break;
        case 'workerpool':
          targetPath = `/${match.params.chainName}/workerpool/${resultObj.address}`;
          break;
        case 'deal':
          targetPath = `/${match.params.chainName}/deal/${resultObj.dealid}`;
          break;
        case 'task':
          targetPath = `/${match.params.chainName}/task/${resultObj.taskid}`;
          break;
        case 'account':
        case 'address':
          targetPath = `/${match.params.chainName}/address/${resultObj.address}`;
          break;
        case 'transaction':
          targetPath = `/${match.params.chainName}/tx/${resultObj.txHash}`;
          break;
        default:
      }
      if (targetPath && targetPath !== history.location) {
        history.push(targetPath);
      }
    }
  }, [resultType, resultObj, match.params.chainName, history]);

  const passedProps = {
    loading,
    isValid,
    error,
    resultType,
    ...(resultType && { [resultType]: resultObj }),
  };

  return <Search {...passedProps} />;
}
