import React, { useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useSubscription } from '@apollo/client';
import { chainIdSelector } from '../../store/selectors/chain';
import { claimDealAsync } from '../../store/actions/user';
import Deal from '../presentational/Deal';
import { useWallet } from '@iexec/react-wallet-manager';
import { isSupportedChain } from '../../utils/chain';

const TASKS_PAGE_LENGTH = 20;

const DEAL_SUBSCRIPTION = gql`
  subscription DealSearch($searchId: ID!) {
    deal(id: $searchId) {
      dealid: id
      timestamp
      startTime
      app {
        address: id
        name
      }
      dataset {
        address: id
        name
      }
      workerpool {
        address: id
        description
      }
      beneficiary {
        address: id
      }
      callback {
        address: id
      }
      appPrice
      datasetPrice
      workerpoolPrice
      params
      tag
      trust
      category {
        catid: id
        name
        workClockTimeRef
        description
      }
      botSize
      botFirst
      completedTasksCount
      claimedTasksCount
      requester {
        address: id
      }
      dealEvents: events(orderBy: timestamp, orderDirection: asc) {
        timestamp
        id
        type: __typename
        transaction {
          txHash: id
        }
      }
    }
  }
`;

const DEALTASKS_SUBSCRIPTION = gql`
  subscription DealTasks($searchId: ID!, $length: Int = 20, $skip: Int = 0) {
    deal(id: $searchId) {
      tasks(orderBy: index, orderDirection: asc, first: $length, skip: $skip) {
        taskid: id
        index
        status
        finalDeadline
      }
    }
  }
`;

const DEALTASKS_HAS_NEXT_SUBSCRIPTION = gql`
  subscription NextDealTasks($searchId: ID!, $next: Int = 0) {
    deal(id: $searchId) {
      next: tasks(orderBy: index, orderDirection: asc, first: 1, skip: $next) {
        id
      }
    }
  }
`;

export default function DealContainer(props) {
  const { dealid } = props;

  const { connected, chainId: walletChainId } = useWallet();
  const isConnected = connected && isSupportedChain(walletChainId);

  const chainId = useSelector(chainIdSelector);
  const dispatch = useDispatch();

  const search = dealid?.toLowerCase();

  const [tasksPage, setTasksPage] = useState(0);

  const dealSub = useSubscription(DEAL_SUBSCRIPTION, {
    variables: { searchId: search },
  });

  const dealSubResults = !dealSub.loading ? dealSub?.data?.deal : undefined;

  const loading = dealSub.loading;

  const tasksSub = useSubscription(DEALTASKS_SUBSCRIPTION, {
    variables: {
      searchId: search,
      skip: TASKS_PAGE_LENGTH * tasksPage,
      length: TASKS_PAGE_LENGTH,
    },
  });
  const tasksSubHasNext = useSubscription(DEALTASKS_HAS_NEXT_SUBSCRIPTION, {
    variables: { searchId: search, next: TASKS_PAGE_LENGTH * (tasksPage + 1) },
  });

  const loadingTasks = tasksSub.loading || tasksSubHasNext.loading;

  const deal = {
    ...(dealSubResults?.dealid && { dealid: dealSubResults?.dealid }),
    ...(dealSubResults?.timestamp && { timestamp: dealSubResults?.timestamp }),
    ...(dealSubResults?.startTime && { startTime: dealSubResults?.startTime }),
    ...(dealSubResults?.app?.address && {
      app: dealSubResults?.app?.address,
    }),
    ...(dealSubResults?.dataset?.address && {
      dataset: dealSubResults?.dataset?.address,
    }),
    ...(dealSubResults?.workerpool?.address && {
      workerpool: dealSubResults?.workerpool?.address,
    }),
    ...(dealSubResults?.app?.name && {
      appName: dealSubResults?.app?.name,
    }),
    ...(dealSubResults?.dataset?.name && {
      datasetName: dealSubResults?.dataset?.name,
    }),
    ...(dealSubResults?.workerpool?.description && {
      workerpoolDescription: dealSubResults?.workerpool?.description,
    }),
    ...(dealSubResults?.appPrice && {
      appPrice: dealSubResults?.appPrice,
    }),
    ...(dealSubResults?.datasetPrice && {
      datasetPrice: dealSubResults?.datasetPrice,
    }),
    ...(dealSubResults?.workerpoolPrice && {
      workerpoolPrice: dealSubResults?.workerpoolPrice,
    }),
    ...(dealSubResults?.params && { params: dealSubResults?.params }),
    ...(dealSubResults?.tag && { tag: dealSubResults?.tag }),
    ...(dealSubResults?.trust && { trust: dealSubResults?.trust }),
    ...(dealSubResults?.category?.catid && {
      catid: dealSubResults?.category?.catid,
    }),
    ...(dealSubResults?.category?.description && {
      categoryDescription: dealSubResults?.category?.description,
    }),
    ...(dealSubResults?.category?.workClockTimeRef && {
      workClockTimeRef: dealSubResults?.category?.workClockTimeRef,
    }),
    ...(dealSubResults?.requester?.address && {
      requester: dealSubResults?.requester?.address,
    }),
    ...(dealSubResults?.beneficiary?.address && {
      beneficiary: dealSubResults?.beneficiary?.address,
    }),
    ...(dealSubResults?.callback?.address && {
      callback: dealSubResults?.callback?.address,
    }),
    ...(dealSubResults?.botSize && {
      botSize: dealSubResults?.botSize,
    }),
    ...(dealSubResults?.botFirst && {
      botFirst: dealSubResults?.botFirst,
    }),
    ...(dealSubResults?.completedTasksCount && {
      completedTasksCount: dealSubResults?.completedTasksCount,
    }),
    ...(dealSubResults?.claimedTasksCount && {
      claimedTasksCount: dealSubResults?.claimedTasksCount,
    }),
    // dealEvents
    ...(dealSubResults?.dealEvents?.length > 0 && {
      dealEvents: dealSubResults?.dealEvents
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return -1;
          }
          if (a.timestamp < b.timestamp) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 1;
        })
        .map((dealEvent) => ({
          type: dealEvent?.type,
          timestamp: dealEvent?.timestamp,
          txHash: dealEvent?.transaction?.txHash,
          ...(dealEvent?.worker?.address && {
            worker: dealEvent?.worker?.address,
          }),
        })),
    }),
  };

  const dealTasks = {
    pageSize: TASKS_PAGE_LENGTH,
    loading: loadingTasks,
    page: tasksPage,
    setPage: setTasksPage,
    hasNext: !loadingTasks && tasksSubHasNext.data?.deal?.next?.length === 1,
    tasks:
      (!loadingTasks &&
        tasksSub?.data?.deal?.tasks?.map((task) => ({
          index: task?.index,
          taskid: task?.taskid,
          status: task?.status,
          finalDeadline: task?.finalDeadline,
        }))) ||
      [],
  };

  const botSize = dealSubResults?.botSize;
  const botFirst = dealSubResults?.botFirst;
  const handleClaim = useCallback(() => {
    dispatch(
      claimDealAsync.request({
        dealid,
        botFirst: parseInt(botFirst),
        botSize: parseInt(botSize),
      }),
    );
  }, [dispatch, dealid, botFirst, botSize]);

  const passedProps = {
    tasksSub,
    loading,
    deal,
    dealTasks,
    chainId,
    isConnected,
    onClaim: handleClaim,
  };

  return <Deal {...passedProps} />;
}
