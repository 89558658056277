import Debug from 'debug';
import { all, put, takeEvery, call } from 'redux-saga/effects';
import IExecConfig from 'iexec/IExecConfig';
import IExecENSModule from 'iexec/IExecENSModule';
import IExecNetworkModule from 'iexec/IExecNetworkModule';
import { getWeb3 } from '../../services/web3';
import * as ensActions from '../actions/ens';
import { INFURA_PROJECT_ID } from '../../config';
import { sleep } from '../../utils/utils';

const debug = Debug('saga:user');

export function* lookupAddress(action) {
  const { address, chainId } = action;
  try {
    let ethProvider;
    try {
      ethProvider = getWeb3();
    } catch (e) {
      ethProvider = chainId;
    }
    const config = new IExecConfig(
      {
        ethProvider,
      },
      { providerOptions: { infura: INFURA_PROJECT_ID } },
    );
    const ensModule = IExecENSModule.fromConfig(config);
    const networkModule = IExecNetworkModule.fromConfig(config);
    const network = yield call(networkModule.getNetwork);
    if (`${network.chainId}` !== `${chainId}`) {
      throw Error('Wrong chain id');
    }
    const ens = yield call(ensModule.lookupAddress, address);
    yield put(ensActions.ensLookupAsync.success({ address, chainId, ens }));
  } catch (error) {
    debug('lookupAddress()', error);
    yield sleep(10000); // wait to avoid imediate retry
    yield put(ensActions.ensLookupAsync.failure({ address, chainId }));
  }
}

export function* watchUser() {
  yield takeEvery('ENS_LOOKUP_REQUEST', lookupAddress);
}

export default function* userSaga() {
  yield all([watchUser()]);
}
