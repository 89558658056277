export const downloadResultAsync = {
  request: ({ chainId, taskid, results }) => ({
    type: 'DOWNLOAD_RESULT_REQUEST',
    chainId,
    taskid,
    results,
  }),
  success: () => ({ type: 'DOWNLOAD_RESULT_SUCCESS' }),
  failure: (error) => ({ type: 'DOWNLOAD_RESULT_FAILURE', error }),
  cancelled: () => ({ type: 'DOWNLOAD_RESULT_CANCELLED' }),
};

export const claimTaskAsync = {
  request: (taskid) => ({ type: 'CLAIM_TASK_REQUEST', taskid }),
  success: (txHash) => ({ type: 'CLAIM_TASK_SUCCESS', txHash }),
  failure: (error) => ({ type: 'CLAIM_TASK_FAILURE', error }),
  cancelled: () => ({ type: 'CLAIM_TASK_CANCELLED' }),
};

export const claimDealAsync = {
  request: (deal) => ({ type: 'CLAIM_DEAL_REQUEST', deal }),
  success: (txHash) => ({ type: 'CLAIM_DEAL_SUCCESS', txHash }),
  failure: (error) => ({ type: 'CLAIM_DEAL_FAILURE', error }),
  cancelled: () => ({ type: 'CLAIM_DEAL_CANCELLED' }),
};
