const createMediaQuery = (breakpoint) => ({
  up: `@media (min-width: ${breakpoint}px)`,
  down: `@media (max-width: ${breakpoint}px)`,
});

export const breakpoints = {
  mobile: 540,
  small: 600,
  medium: 1000,
  big: 1500,
};

export const mediaQueries = {
  mobile: createMediaQuery(breakpoints.mobile),
  small: createMediaQuery(breakpoints.small),
  medium: createMediaQuery(breakpoints.medium),
  big: createMediaQuery(breakpoints.big),
};

export const mobileDisplay = window.innerWidth <= breakpoints.mobile;

export const mediumDisplay = window.innerWidth <= breakpoints.medium;

export const displayRatio = (number) =>
  mobileDisplay ? Math.round(0.875 * number) : number;
