import React from 'react';
import MuiDrawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { FaTimes } from 'react-icons/fa';
import classNames from 'classnames';
import { set } from '../store/actions/app';
import ChainChip from './ChainChip';
import {
  EXPLORER_BASE_URL,
  DAPPSTORE_BASE_URL,
} from '../config';

const styles = (theme) => ({
  root: {
    backgroundColor: '#010101',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    opacity: 0.85,
    width: 200,
  },
  row: {
    display: 'flex',
    width: '100%',
    '& a': {
      color: theme.palette.common.white,
      fontSize: '1em',
      lineHeight: '50px',
      paddingLeft: '30px',
      textDecoration: 'none',
      width: '100%',
    },
    '& a:hover': {
      color: theme.palette.primary.dark,
    },
  },
  close: {
    justifyContent: 'space-between',
    paddingLeft: '30px',
  },
  closeButton: {
    color: theme.palette.primary.dark,
    fontSize: '1.7em',
    padding: '30px 20px',
    cursor: 'pointer',
  },
});

const Drawer = ({ classes, children, isDrawerOpen, toggleDrawer }) => {
  return (
    <MuiDrawer
      open={isDrawerOpen}
      anchor="right"
      onClose={() => toggleDrawer()}
    >
      <div className={classes.root}>
        <div className={classNames(classes.row, classes.close)}>
          <ChainChip />
          <div
            onClick={() => toggleDrawer()}
            onKeyDown={() => toggleDrawer()}
            className={classes.closeButton}
          >
            <FaTimes />
          </div>
        </div>
        <div className={classes.row}>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={DAPPSTORE_BASE_URL}
          >
            Dapp Store
          </a>
        </div>
        <div className={classes.row}>
          <a target="_blank" rel="noreferrer noopener" href={EXPLORER_BASE_URL}>
            Explorer
          </a>
        </div>
        <div className={classes.row}>
          <a target="_blank" rel="noreferrer noopener" href={'https://iex.ec'}>
            Website
          </a>
        </div>
      </div>
    </MuiDrawer>
  );
};

const mapStateToProps = (state) => ({
  isDrawerOpen: state.app.isDrawerOpen,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDrawer: () => dispatch(set.toggleDrawer()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Drawer));
