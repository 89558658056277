import React from 'react';
import TextNoWrap from './TextNoWrap';
import TxLink from './TxLink';
import TaskLink from './TaskLink';
import AddressLink from './AddressLink';
import RlcDisplay from '../RlcDisplay';
import { rlcToNano } from '../../utils/maths';

const AccountEvent = (props) => {
  const { eventName, type, txHash, address, value, taskid, chainId } = props;

  return (
    <>
      {(eventName || type) && <>{eventName || type} </>}
      {txHash && (
        <>
          <TextNoWrap>
            <TxLink txHash={txHash} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}

      {value && (
        <>
          <TextNoWrap>
            <RlcDisplay amount={rlcToNano(value)} />
          </TextNoWrap>{' '}
        </>
      )}

      {address && (
        <>
          <TextNoWrap>
            {'on account '}
            <AddressLink address={address} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
      {taskid && (
        <>
          <TextNoWrap>
            {'task '}
            <TaskLink taskid={taskid} chainId={chainId} />
          </TextNoWrap>{' '}
        </>
      )}
    </>
  );
};

export default AccountEvent;
