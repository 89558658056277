import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { gql, useSubscription } from '@apollo/client';
import Task from '../presentational/Task';
import { chainIdSelector } from '../../store/selectors/chain';
import { claimTaskAsync, downloadResultAsync } from '../../store/actions/user';
import { useWallet } from '@iexec/react-wallet-manager';
import { isSupportedChain } from '../../utils/chain';

const TASK_SUBSCRIPTION = gql`
  subscription TaskSearch($searchId: ID!) {
    task(id: $searchId) {
      taskid: id
      timestamp
      deal {
        dealid: id
        app {
          address: id
          name
        }
        dataset {
          address: id
          name
        }
        workerpool {
          address: id
          description
        }
        beneficiary {
          address: id
        }
        callback {
          address: id
        }
        tag
        trust
        category {
          catid: id
          name
          workClockTimeRef
          description
        }
        botSize
        botFirst
      }
      requester {
        address: id
      }
      index
      status
      contributionDeadline
      revealDeadline
      finalDeadline
      consensus
      resultDigest
      results
      resultsCallback

      # TODO index in subgraph
      # contributions(orderBy: timestamp, orderDirection: asc) {
      #   timestamp
      #   worker {
      #     address: id
      #     score
      #   }
      #   hash
      #   seal
      #   challenge
      # }

      taskEvents: events(orderBy: timestamp, orderDirection: asc) {
        timestamp
        id
        type: __typename
        transaction {
          txHash: id
        }
        # ... on TaskInitialize { } # nothing more
        ... on TaskContribute {
          worker {
            address: id
          }
          hash
        }
        ... on TaskConsensus {
          consensus
        }
        ... on TaskReveal {
          digest
          worker {
            address: id
          }
        }
        # ... on TaskReopen { } # nothing more
        ... on TaskFinalize {
          results
          # resultsCallback # TODO add in subgraph
        }
        # ... on TaskClaimed { } # nothing more
      }
    }
  }
`;

export default function TaskContainer(props) {
  const { taskid } = props;

  const { connected, chainId: walletChainId } = useWallet();
  const isConnected = connected && isSupportedChain(walletChainId);

  const chainId = useSelector(chainIdSelector);
  const dispatch = useDispatch();

  const search = taskid?.toLowerCase();

  const taskSub = useSubscription(TASK_SUBSCRIPTION, {
    variables: { searchId: search },
  });

  const taskSubResults = !taskSub.loading ? taskSub?.data?.task : undefined;

  const loading = taskSub.loading;

  const task = {
    ...(taskSubResults?.taskid && { taskid: taskSubResults?.taskid }),
    ...(taskSubResults?.deal?.dealid && {
      dealid: taskSubResults?.deal?.dealid,
    }),
    ...(taskSubResults?.deal?.app?.address && {
      app: taskSubResults?.deal?.app?.address,
    }),
    ...(taskSubResults?.deal?.dataset?.address && {
      dataset: taskSubResults?.deal?.dataset?.address,
    }),
    ...(taskSubResults?.deal?.workerpool?.address && {
      workerpool: taskSubResults?.deal?.workerpool?.address,
    }),
    ...(taskSubResults?.deal?.app?.name && {
      appName: taskSubResults?.deal?.app?.name,
    }),
    ...(taskSubResults?.deal?.dataset?.name && {
      datasetName: taskSubResults?.deal?.dataset?.name,
    }),
    ...(taskSubResults?.deal?.workerpool?.description && {
      workerpoolDescription: taskSubResults?.deal?.workerpool?.description,
    }),
    ...(taskSubResults?.deal?.tag && { tag: taskSubResults?.deal?.tag }),
    ...(taskSubResults?.deal?.trust && { trust: taskSubResults?.deal?.trust }),
    ...(taskSubResults?.deal?.category?.catid && {
      catid: taskSubResults?.deal?.category?.catid,
    }),
    ...(taskSubResults?.deal?.category?.description && {
      categoryDescription: taskSubResults?.deal?.category?.description,
    }),
    ...(taskSubResults?.deal?.category?.workClockTimeRef && {
      workClockTimeRef: taskSubResults?.deal?.category?.workClockTimeRef,
    }),
    ...(taskSubResults?.requester?.address && {
      requester: taskSubResults?.requester?.address,
    }),
    ...(taskSubResults?.deal?.beneficiary?.address && {
      beneficiary: taskSubResults?.deal?.beneficiary?.address,
    }),
    ...(taskSubResults?.deal?.callback?.address && {
      callback: taskSubResults?.deal?.callback?.address,
    }),
    ...(taskSubResults?.index && { index: taskSubResults?.index }),
    ...(taskSubResults?.status && { status: taskSubResults?.status }),
    ...(taskSubResults?.contributionDeadline && {
      contributionDeadline: taskSubResults?.contributionDeadline,
    }),
    ...(taskSubResults?.revealDeadline && {
      revealDeadline: taskSubResults?.revealDeadline,
    }),
    ...(taskSubResults?.finalDeadline && {
      finalDeadline: taskSubResults?.finalDeadline,
    }),
    ...(taskSubResults?.consensus && { consensus: taskSubResults?.consensus }),
    ...(taskSubResults?.resultDigest && {
      resultDigest: taskSubResults?.resultDigest,
    }),
    ...(taskSubResults?.results && { results: taskSubResults?.results }),
    ...(taskSubResults?.resultsCallback && {
      resultsCallback: taskSubResults?.resultsCallback,
    }),
    // contributions
    ...(taskSubResults?.contributions?.length > 0 && {
      contributions: taskSubResults?.contributions.map((contribution) => ({
        worker: contribution?.worker?.address,
        timestamp: contribution?.timestamp,
        to: contribution?.to,
        seal: contribution?.seal,
        challenge: contribution?.challenge,
      })),
    }),
    // taskEvents
    ...(taskSubResults?.taskEvents?.length > 0 && {
      taskEvents: taskSubResults?.taskEvents
        .sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return -1;
          }
          if (a.timestamp < b.timestamp) {
            return 1;
          }
          if (a.id < b.id) {
            return -1;
          }
          return 1;
        })
        .map((taskEvent) => ({
          type: taskEvent?.type,
          timestamp: taskEvent?.timestamp,
          txHash: taskEvent?.transaction?.txHash,
          ...(taskEvent?.worker?.address && {
            worker: taskEvent?.worker?.address,
          }),
          ...(taskEvent?.hash && { hash: taskEvent?.hash }),
          ...(taskEvent?.digest && { digest: taskEvent?.digest }),
          ...(taskEvent?.consensus && { consensus: taskEvent?.consensus }),
          ...(taskEvent?.results && { results: taskEvent?.results }),
          ...(taskEvent?.resultsCallback && {
            digest: taskEvent?.resultsCallback,
          }),
        })),
    }),
  };

  const handleClaim = useCallback(() => {
    dispatch(claimTaskAsync.request(task?.taskid));
  }, [dispatch, task?.taskid]);

  const handleDownloadResult = useCallback(() => {
    dispatch(
      downloadResultAsync.request({
        taskid: task?.taskid,
        chainId,
        results: task?.results,
      }),
    );
  }, [chainId, dispatch, task?.results, task?.taskid]);

  const passedProps = {
    loading,
    task,
    chainId,
    isConnected,
    onClaim: handleClaim,
    onDownload: handleDownloadResult,
  };

  return <Task {...passedProps} />;
}
