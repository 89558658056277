import React from 'react';
import CopyButton from './CopyButton';

const JsonBlock = (props) => {
  const { children: raw } = props;
  let display;
  try {
    display = JSON.stringify(JSON.parse(raw), null, 2);
  } catch (e) {
    display = raw;
  }

  return (
    <>
      <div style={{ display: 'inline-block', whiteSpace: 'pre' }}>
        {display}
      </div>
      <CopyButton toClipboard={raw} />
    </>
  );
};

export default JsonBlock;
