import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import moment from 'moment';
import Table, { Header } from './Table';
import { displayRatio } from '../../utils/display';
import StatusCell from './StatusCell';

export default function Contributions({
  title,
  loading,
  pageSize,
  minRows,
  page,
  setPage,
  hasNext,
  contributions,
}) {
  const match = useRouteMatch();
  const link = (props) => (
    <Link to={`/${match.params.chain}/task/${props.row.taskid}`}>
      {props.value}
    </Link>
  );
  const columns = [
    {
      Header: () => (
        <Header
          title={title || 'Latest contributions'}
          data={contributions}
          setPageNumber={setPage}
          pageNumber={page}
          pageSize={pageSize}
          hasNext={hasNext}
          loading={loading}
        />
      ),
      columns: [
        {
          minWidth: displayRatio(95),
          maxWidth: displayRatio(120),
          Header: 'Time',
          id: 'blockTimestamp',
          accessor: (e) => moment(e?.timestamp * 1000).fromNow(),
          Cell: link,
        },
        {
          minWidth: displayRatio(100),
          Header: 'Task',
          id: 'taskid',
          accessor: (e) => e?.taskid,
          Cell: link,
        },
        {
          width: displayRatio(90),
          Header: 'Status',
          id: 'status',
          accessor: (e) => (
            <StatusCell
              statusEnum={e?.status}
              timeoutTimestamp={e?.finalDeadline * 1000}
            />
          ),
          Cell: link,
        },
      ],
    },
  ];

  return (
    <Table
      loading={loading}
      pageNumber={page}
      data={contributions}
      columns={columns}
      pageSize={pageSize}
      minRows={minRows}
    />
  );
}
