import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { gql, useSubscription } from '@apollo/client';
import { chainIdSelector } from '../../store/selectors/chain';
import Address from '../presentational/Address';

const PAGE_LENGTH = 10;

const ADDRESS_OVERVIEW_SUBSCRIPTION = gql`
  subscription Search($search: ID!) {
    account(id: $search) {
      address: id
      staked: balance
      locked: frozen
      score
      # counts workaround TODO
      allApps: apps(first: 1000) {
        id
      }
      allDatasets: datasets(first: 1000) {
        id
      }
      allWorkerpools: workerpools(first: 1000) {
        id
      }
      allContributions: contributions(first: 1000) {
        id
      }
      allDealRequester: dealRequester(first: 1000) {
        id
      }
      allDealBeneficiary: dealBeneficiary(first: 1000) {
        id
      }
    }
  }
`;

const ADDRESS_APPS_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $appsPageLength: Int = 20
    $appsPage: Int = 0
    $appsNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      # apps
      apps(
        orderBy: timestamp
        orderDirection: desc
        first: $appsPageLength
        skip: $appsPage
      ) {
        address: id
        name
        timestamp
      }
      appsHasNext: apps(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $appsNextPage
      ) {
        address: id
      }
    }
  }
`;

const ADDRESS_DATASETS_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $datasetsPageLength: Int = 20
    $datasetsPage: Int = 0
    $datasetsNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      # datasets
      datasets(
        orderBy: timestamp
        orderDirection: desc
        first: $datasetsPageLength
        skip: $datasetsPage
      ) {
        address: id
        name
        timestamp
      }
      datasetsHasNext: datasets(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $datasetsNextPage
      ) {
        address: id
      }
    }
  }
`;

const ADDRESS_WORKERPOOLS_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $workerpoolsPageLength: Int = 20
    $workerpoolsPage: Int = 0
    $workerpoolsNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      # workerpools
      workerpools(
        orderBy: timestamp
        orderDirection: desc
        first: $workerpoolsPageLength
        skip: $workerpoolsPage
      ) {
        address: id
        description
        timestamp
      }
      workerpoolsHasNext: workerpools(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $workerpoolsNextPage
      ) {
        address: id
      }
    }
  }
`;

const ADDRESS_CONTRIBUTIONS_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $contributionsPageLength: Int = 20
    $contributionsPage: Int = 0
    $contributionsNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      # worker contributions
      contributions(
        orderBy: timestamp
        orderDirection: desc
        first: $contributionsPageLength
        skip: $contributionsPage
      ) {
        id
        timestamp
        task {
          taskid: id
        }
        status
      }
      contributionsHasNext: contributions(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $contributionsNextPage
      ) {
        id
      }
    }
  }
`;

const ADDRESS_DEALS_REQUESTER_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $dealsRequesterPageLength: Int = 20
    $dealsRequesterPage: Int = 0
    $dealsRequesterNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      # requester
      dealRequester(
        orderBy: timestamp
        orderDirection: desc
        first: $dealsRequesterPageLength
        skip: $dealsRequesterPage
      ) {
        dealid: id
        timestamp
        botSize
        completedTasksCount
        claimedTasksCount
        category {
          workClockTimeRef
        }
        startTime
        app {
          address: id
          name
        }
        dataset {
          address: id
          name
        }
        workerpool {
          address: id
          description
        }
      }
      dealRequesterHasNext: dealRequester(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $dealsRequesterNextPage
      ) {
        id
      }
    }
  }
`;

const ADDRESS_TASKS_REQUESTER_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $tasksRequesterPageLength: Int = 20
    $tasksRequesterPage: Int = 0
    $tasksRequesterNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      taskRequester(
        orderBy: timestamp
        orderDirection: desc
        first: $tasksRequesterPageLength
        skip: $tasksRequesterPage
      ) {
        taskid: id
        timestamp
        status
        finalDeadline
        deal {
          dealid: id
          app {
            address: id
            name
          }
          dataset {
            address: id
            name
          }
          workerpool {
            address: id
            description
          }
        }
      }
      taskRequesterHasNext: taskRequester(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $tasksRequesterNextPage
      ) {
        id
      }
    }
  }
`;

const ADDRESS_DEALS_BENEFICIARY_SUBSCRIPTION = gql`
  subscription Search(
    $search: ID!
    $dealsBeneficiaryPageLength: Int = 20
    $dealsBeneficiaryPage: Int = 0
    $dealsBeneficiaryNextPage: Int = 20
  ) {
    account(id: $search) {
      address: id
      # beneficiary
      dealBeneficiary(
        orderBy: timestamp
        orderDirection: desc
        first: $dealsBeneficiaryPageLength
        skip: $dealsBeneficiaryPage
      ) {
        dealid: id
        timestamp
        botSize
        completedTasksCount
        claimedTasksCount
        category {
          workClockTimeRef
        }
        startTime
        app {
          address: id
          name
        }
        dataset {
          address: id
          name
        }
        workerpool {
          address: id
          description
        }
      }
      dealBeneficiaryHasNext: dealBeneficiary(
        orderBy: timestamp
        orderDirection: desc
        first: 1
        skip: $dealsBeneficiaryNextPage
      ) {
        id
      }
    }
  }
`;

export default function AddressContainer(props) {
  const { address } = props;
  const chainId = useSelector(chainIdSelector);

  const search = address?.toLowerCase();

  const [appsPage, setAppsPage] = useState(0);
  const [datasetsPage, setDatasetsPage] = useState(0);
  const [workerpoolsPage, setWorkerpoolsPage] = useState(0);
  const [contributionsPage, setContributionsPage] = useState(0);
  const [dealsRequesterPage, setDealsRequesterPage] = useState(0);
  const [tasksRequesterPage, setTasksRequesterPage] = useState(0);
  const [dealsBeneficiaryPage, setDealsBeneficiaryPage] = useState(0);

  useEffect(() => {
    setAppsPage(0);
    setDatasetsPage(0);
    setWorkerpoolsPage(0);
    setContributionsPage(0);
    setDealsRequesterPage(0);
    setTasksRequesterPage(0);
    setDealsBeneficiaryPage(0);
  }, [chainId]);

  const addressOverviewSub = useSubscription(ADDRESS_OVERVIEW_SUBSCRIPTION, {
    variables: {
      search: search,
    },
  });

  const addressOverviewSubResults = !addressOverviewSub.loading
    ? addressOverviewSub?.data?.account
    : undefined;

  const loading = addressOverviewSub.loading;

  const addressAppsSub = useSubscription(ADDRESS_APPS_SUBSCRIPTION, {
    variables: {
      search: search,
      appsPage: PAGE_LENGTH * appsPage,
      appsPageLength: PAGE_LENGTH,
      appsNextPage: PAGE_LENGTH * (appsPage + 1),
    },
  });

  const addressAppsSubResults = !addressAppsSub.loading
    ? addressAppsSub?.data?.account
    : undefined;

  const loadingApps = addressAppsSub.loading;

  const addressDatasetsSub = useSubscription(ADDRESS_DATASETS_SUBSCRIPTION, {
    variables: {
      search: search,
      datasetsPage: PAGE_LENGTH * datasetsPage,
      datasetsPageLength: PAGE_LENGTH,
      datasetsNextPage: PAGE_LENGTH * (datasetsPage + 1),
    },
  });

  const addressDatasetsSubResults = !addressDatasetsSub.loading
    ? addressDatasetsSub?.data?.account
    : undefined;

  const loadingDatasets = addressDatasetsSub.loading;

  const addressWorkerpoolsSub = useSubscription(
    ADDRESS_WORKERPOOLS_SUBSCRIPTION,
    {
      variables: {
        search: search,
        workerpoolsPage: PAGE_LENGTH * workerpoolsPage,
        workerpoolsPageLength: PAGE_LENGTH,
        workerpoolsNextPage: PAGE_LENGTH * (workerpoolsPage + 1),
        contributionsPage: PAGE_LENGTH * contributionsPage,
        contributionsPageLength: PAGE_LENGTH,
        contributionsNextPage: PAGE_LENGTH * (contributionsPage + 1),
        dealsRequesterPage: PAGE_LENGTH * dealsRequesterPage,
        dealsRequesterPageLength: PAGE_LENGTH,
        dealsRequesterNextPage: PAGE_LENGTH * (dealsRequesterPage + 1),
        tasksRequesterPage: PAGE_LENGTH * tasksRequesterPage,
        tasksRequesterPageLength: PAGE_LENGTH,
        tasksRequesterNextPage: PAGE_LENGTH * (tasksRequesterPage + 1),
        dealsBeneficiaryPage: PAGE_LENGTH * dealsBeneficiaryPage,
        dealsBeneficiaryPageLength: PAGE_LENGTH,
        dealsBeneficiaryNextPage: PAGE_LENGTH * (dealsBeneficiaryPage + 1),
      },
    },
  );

  const addressWorkerpoolsSubResults = !addressWorkerpoolsSub.loading
    ? addressWorkerpoolsSub?.data?.account
    : undefined;

  const loadingWorkerpools = addressWorkerpoolsSub.loading;

  const addressContributionsSub = useSubscription(
    ADDRESS_CONTRIBUTIONS_SUBSCRIPTION,
    {
      variables: {
        search: search,
        contributionsPage: PAGE_LENGTH * contributionsPage,
        contributionsPageLength: PAGE_LENGTH,
        contributionsNextPage: PAGE_LENGTH * (contributionsPage + 1),
      },
    },
  );

  const addressContributionsSubResults = !addressContributionsSub.loading
    ? addressContributionsSub?.data?.account
    : undefined;

  const loadingContributions = addressContributionsSub.loading;

  const addressDealsRequesterSub = useSubscription(
    ADDRESS_DEALS_REQUESTER_SUBSCRIPTION,
    {
      variables: {
        search: search,
        dealsRequesterPage: PAGE_LENGTH * dealsRequesterPage,
        dealsRequesterPageLength: PAGE_LENGTH,
        dealsRequesterNextPage: PAGE_LENGTH * (dealsRequesterPage + 1),
      },
    },
  );

  const addressDealsRequesterSubResults = !addressDealsRequesterSub.loading
    ? addressDealsRequesterSub?.data?.account
    : undefined;

  const loadingDealsRequester = addressDealsRequesterSub.loading;

  const addressTasksRequesterSub = useSubscription(
    ADDRESS_TASKS_REQUESTER_SUBSCRIPTION,
    {
      variables: {
        search: search,
        tasksRequesterPage: PAGE_LENGTH * tasksRequesterPage,
        tasksRequesterPageLength: PAGE_LENGTH,
        tasksRequesterNextPage: PAGE_LENGTH * (tasksRequesterPage + 1),
      },
    },
  );

  const addressTasksRequesterSubResults = !addressTasksRequesterSub.loading
    ? addressTasksRequesterSub?.data?.account
    : undefined;

  const loadingTasksRequester = addressTasksRequesterSub.loading;

  const addressDealsBeneficiarySub = useSubscription(
    ADDRESS_DEALS_BENEFICIARY_SUBSCRIPTION,
    {
      variables: {
        search: search,
        dealsBeneficiaryPage: PAGE_LENGTH * dealsBeneficiaryPage,
        dealsBeneficiaryPageLength: PAGE_LENGTH,
        dealsBeneficiaryNextPage: PAGE_LENGTH * (dealsBeneficiaryPage + 1),
      },
    },
  );

  const addressDealsBeneficiarySubResults = !addressDealsBeneficiarySub.loading
    ? addressDealsBeneficiarySub?.data?.account
    : undefined;

  const loadingDealsBeneficiary = addressDealsBeneficiarySub.loading;

  const accountOverview = {
    ...(addressOverviewSubResults?.address && {
      address: addressOverviewSubResults?.address,
    }),
    ...(addressOverviewSubResults?.staked && {
      staked: addressOverviewSubResults?.staked,
    }),
    ...(addressOverviewSubResults?.locked && {
      locked: addressOverviewSubResults?.locked,
    }),
    ...(addressOverviewSubResults?.score && {
      score: addressOverviewSubResults?.score,
    }),
    // counts
    ...(addressOverviewSubResults?.allApps && {
      appsCount: addressOverviewSubResults?.allApps.length,
    }),
    ...(addressOverviewSubResults?.allDatasets && {
      datasetsCount: addressOverviewSubResults?.allDatasets.length,
    }),
    ...(addressOverviewSubResults?.allWorkerpools && {
      workerpoolsCount: addressOverviewSubResults?.allWorkerpools.length,
    }),
    ...(addressOverviewSubResults?.allContributions && {
      contributionsCount: addressOverviewSubResults?.allContributions.length,
    }),
    ...(addressOverviewSubResults?.allDealsRequester && {
      dealsRequesterCount: addressOverviewSubResults?.allDealsRequester.length,
    }),
    ...(addressOverviewSubResults?.allDealsBeneficiary && {
      dealsBeneficiaryCount:
        addressOverviewSubResults?.allDealsBeneficiary.length,
    }),
  };

  const accountApps = {
    ...(addressAppsSubResults?.apps?.length > 0 && {
      apps: addressAppsSubResults?.apps.map((app) => ({
        address: app?.address,
        name: app?.name,
        timestamp: app?.timestamp,
      })),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingApps,
    page: appsPage,
    setPage: setAppsPage,
    hasNext: addressAppsSubResults?.appsHasNext?.length > 0,
  };

  const accountDatasets = {
    ...(addressDatasetsSubResults?.datasets?.length > 0 && {
      datasets: addressDatasetsSubResults?.datasets.map((dataset) => ({
        address: dataset?.address,
        name: dataset?.name,
        timestamp: dataset?.timestamp,
      })),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingDatasets,
    page: datasetsPage,
    setPage: setDatasetsPage,
    hasNext: addressDatasetsSubResults?.datasetsHasNext?.length > 0,
  };

  const accountWorkerpools = {
    ...(addressWorkerpoolsSubResults?.workerpools?.length > 0 && {
      workerpools: addressWorkerpoolsSubResults?.workerpools.map(
        (workerpool) => ({
          address: workerpool?.address,
          description: workerpool?.description,
          timestamp: workerpool?.timestamp,
        }),
      ),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingWorkerpools,
    page: workerpoolsPage,
    setPage: setWorkerpoolsPage,
    hasNext: addressWorkerpoolsSubResults?.workerpoolsHasNext?.length > 0,
  };

  const accountContributions = {
    ...(addressContributionsSubResults?.contributions?.length > 0 && {
      contributions: addressContributionsSubResults?.contributions.map(
        (contribution) => ({
          taskid: contribution?.task?.taskid,
          status: contribution?.status,
          timestamp: contribution?.timestamp,
        }),
      ),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingContributions,
    page: contributionsPage,
    setPage: setContributionsPage,
    hasNext: addressContributionsSubResults?.contributionsHasNext?.length > 0,
  };

  const accountRequesterDeals = {
    ...(addressDealsRequesterSubResults?.dealRequester?.length > 0 && {
      deals: addressDealsRequesterSubResults?.dealRequester.map((deal) => ({
        dealid: deal?.dealid,
        status: deal?.status,
        timestamp: deal?.timestamp,
        app: deal?.app && {
          address: deal?.app?.address,
          name: deal?.app?.name,
        },
        dataset: deal?.dataset && {
          address: deal?.dataset?.address,
          name: deal?.dataset?.name,
        },
        workerpool: deal?.workerpool && {
          address: deal?.workerpool?.address,
          description: deal?.workerpool?.description,
        },
        botSize: deal?.botSize,
        completedTasksCount: deal?.completedTasksCount,
        claimedTasksCount: deal?.claimedTasksCount,
        startTime: deal?.startTime,
        category: deal?.category && {
          workClockTimeRef: deal?.category?.workClockTimeRef,
        },
      })),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingDealsRequester,
    page: dealsRequesterPage,
    setPage: setDealsRequesterPage,
    hasNext: addressDealsRequesterSubResults?.dealRequesterHasNext?.length > 0,
  };

  const accountRequesterTasks = {
    ...(addressTasksRequesterSubResults?.taskRequester?.length > 0 && {
      tasks: addressTasksRequesterSubResults?.taskRequester.map((task) => ({
        taskid: task?.taskid,
        status: task?.status,
        timestamp: task?.timestamp,
        finalDeadline: task?.finalDeadline,
        app: task?.deal?.app && {
          address: task?.deal?.app?.address,
          name: task?.deal?.app?.name,
        },
        dataset: task?.deal?.dataset && {
          address: task?.deal?.dataset?.address,
          name: task?.deal?.dataset?.name,
        },
        workerpool: task?.deal?.workerpool && {
          address: task?.deal?.workerpool?.address,
          description: task?.deal?.workerpool?.description,
        },
      })),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingTasksRequester,
    page: tasksRequesterPage,
    setPage: setTasksRequesterPage,
    hasNext: addressTasksRequesterSubResults?.taskRequesterHasNext?.length > 0,
  };

  const accountBeneficiaryDeals = {
    ...(addressDealsBeneficiarySubResults?.dealBeneficiary?.length > 0 && {
      deals: addressDealsBeneficiarySubResults?.dealBeneficiary.map((deal) => ({
        dealid: deal?.dealid,
        status: deal?.status,
        timestamp: deal?.timestamp,
        app: deal?.app && {
          address: deal?.app?.address,
          name: deal?.app?.name,
        },
        dataset: deal?.dataset && {
          address: deal?.dataset?.address,
          name: deal?.dataset?.name,
        },
        workerpool: deal?.workerpool && {
          address: deal?.workerpool?.address,
          description: deal?.workerpool?.description,
        },
        botSize: deal?.botSize,
        completedTasksCount: deal?.completedTasksCount,
        claimedTasksCount: deal?.claimedTasksCount,
        startTime: deal?.startTime,
        category: deal?.category && {
          workClockTimeRef: deal?.category?.workClockTimeRef,
        },
      })),
    }),
    pageSize: PAGE_LENGTH,
    loading: loadingDealsBeneficiary,
    page: dealsBeneficiaryPage,
    setPage: setDealsBeneficiaryPage,
    hasNext:
      addressDealsBeneficiarySubResults?.dealBeneficiaryHasNext?.length > 0,
  };

  const passedProps = {
    chainId,
    loading,
    accountOverview,
    accountApps,
    accountDatasets,
    accountWorkerpools,
    accountContributions,
    accountRequesterDeals,
    accountRequesterTasks,
    accountBeneficiaryDeals,
  };

  return <Address {...passedProps} />;
}
