import { combineReducers } from 'redux';

function isDrawerOpen(state = false, action) {
  switch (action.type) {
    case 'TOGGLE_DRAWER':
      return !state;
    default:
      return state;
  }
}

function searcherValue(state = '', action) {
  switch (action.type) {
    case 'SEARCH_VALUE':
      return action.value;
    default:
      return state;
  }
}

export default combineReducers({
  searcherValue,
  isDrawerOpen,
});
