export const setShowProviderModal = (bool) => ({
  type: 'SET_SHOW_PROVIDER_MODAL',
  bool,
});

export const clear = () => ({
  type: 'CLEAR_ACCOUNT_MANAGER',
});

export const setShowModal = (bool) => ({
  type: 'SET_SHOW_ACCOUNT_MANAGER_MODAL',
  bool,
});

export const setRunGuide = (bool) => ({
  type: 'SET_RUN_ACCOUNT_MANAGER_GUIDE',
  bool,
});

export const setActiveTab = (value) => ({
  type: 'SET_ACCOUNT_ACTIVE_TAB',
  value,
});
