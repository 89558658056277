import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import 'react-table-6/react-table.css';
import history, { getCurrentChainDetails } from './services/history';
import { loadState, saveState } from './utils/localStorage';
import explorerApp from './store/reducers/reducers';
import sagaWatchers from './store/sagas/sagas';
import './index.css';
import App from './routes/App';

window.onbeforeunload = () =>
  saveState({
    accountManager: {
      runGuide: store.getState().accountManager.runGuide,
    },
    chain: {
      chainId: store.getState().chain.chainId,
    },
  });

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const prevState = loadState();

let chainId;
//init chain from url path
const urlChainDetails = getCurrentChainDetails();
if (urlChainDetails.chainId) {
  chainId = urlChainDetails.chainId;
}
//init chain from previous session
else if (prevState && prevState.chain) {
  chainId = prevState.chain.chainId;
}

let store = createStore(
  explorerApp(history),
  { ...loadState(), chain: { chainId } },
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(routerMiddleware(history)),
  ),
);
sagaMiddleware.run(sagaWatchers);

store.subscribe(() => {});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);
