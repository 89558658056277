import { chains } from '../utils/chain';
import { createBrowserHistory as createHistory } from 'history';

const history = createHistory();

export const getCurrentChainDetails = () => {
  const chainKey = history?.location?.pathname?.split('/')[1];
  const chainId = (chainKey && chains[chainKey]?.id) || undefined;
  return {
    chainId,
  };
};

export default history;
