import React from 'react';
import moment from 'moment';
import DealLink from './DealLink';
import AppLink from './AppLink';
import DatasetLink from './DatasetLink';
import WorkerpoolLink from './WorkerpoolLink';
import Details from './Details';
import AddressLink from './AddressLink';
import Loading from './Loading';
import NotFound from './NotFound';
import Bytes from './Bytes';
import DealEvent from './DealEvent';
import TextNoWrap from './TextNoWrap';
import Tasks from './Tasks';
import JsonBlock from './JsonBlock';
import ActionLink from './ActionLink';
import ContentWrapper from './ContentWrapper';

const Deal = (props) => {
  const { loading, deal, dealTasks, chainId, isConnected, onClaim } = props;
  if (loading) {
    return <Loading />;
  }
  if (Object.keys(deal).length === 0) {
    return <NotFound />;
  }
  const dealDeadline =
    deal?.startTime &&
    deal?.workClockTimeRef &&
    (parseInt(deal?.startTime) + parseInt(deal?.workClockTimeRef) * 10) * 1000;

  const tasksCount = parseInt(deal?.botSize) || 1;
  const completedTasksCount = parseInt(deal?.completedTasksCount) || 0;
  const claimedTasksCount = parseInt(deal?.claimedTasksCount) || 0;
  const pendingTasksCount =
    tasksCount - (completedTasksCount + claimedTasksCount);

  const completedRatio = completedTasksCount / tasksCount;
  const claimedRatio = claimedTasksCount / tasksCount;
  const pendingRatio = pendingTasksCount / tasksCount;

  const isClaimable =
    dealDeadline < Date.now() && isConnected && pendingRatio > 0;

  const details = {
    ...(deal.dealid && {
      Dealid: (
        <DealLink
          dealid={deal.dealid}
          chainId={chainId}
          hideInternalLink={true}
        />
      ),
    }),
    ...(deal.catid !== undefined &&
      deal.workClockTimeRef !== undefined && {
        Category: (
          <>
            {deal.catid} ({deal.workClockTimeRef * 10} sec)
          </>
        ),
      }),
    ...(deal.tag && {
      Tag: <Bytes>{deal.tag}</Bytes>,
    }),
    ...(deal.trust && {
      Trust: deal.trust,
    }),
    ...(deal.app && {
      App: (
        <TextNoWrap>
          {deal.appName} <AppLink address={deal.app} chainId={chainId} />
        </TextNoWrap>
      ),
      'App price': deal.appPrice,
    }),
    ...(deal.dataset && {
      Dataset: (
        <TextNoWrap>
          {deal.datasetName}{' '}
          <DatasetLink address={deal.dataset} chainId={chainId} />
        </TextNoWrap>
      ),
      'Dataset price': deal.datasetPrice,
    }),
    ...(deal.workerpool && {
      Workerpool: (
        <TextNoWrap>
          {deal.workerpoolDescription}{' '}
          <WorkerpoolLink address={deal.workerpool} chainId={chainId} />
        </TextNoWrap>
      ),
      'Workerpool price': deal.workerpoolPrice,
    }),

    ...(deal.requester && {
      Requester: <AddressLink address={deal.requester} chainId={chainId} />,
    }),
    ...(deal.beneficiary && {
      Beneficiary: <AddressLink address={deal.beneficiary} chainId={chainId} />,
    }),
    ...(deal.callback && {
      'Callback contract': (
        <AddressLink address={deal.callback} chainId={chainId} />
      ),
    }),
    ...(deal.params && {
      Params: <JsonBlock>{deal.params}</JsonBlock>,
    }),
    ...((pendingRatio || completedRatio || claimedRatio) && {
      Status: (
        <span>
          {completedRatio > 0 && (
            <>
              <TextNoWrap>{completedRatio * 100}% COMPLETED</TextNoWrap>{' '}
            </>
          )}
          {completedRatio > 0 && claimedRatio > 0 && <> - </>}
          {claimedRatio > 0 && (
            <>
              <TextNoWrap>{claimedRatio * 100}% CLAIMED</TextNoWrap>{' '}
            </>
          )}
          {claimedRatio > 0 && pendingRatio > 0 && <> - </>}
          {pendingRatio > 0 && (
            <>
              <TextNoWrap>
                {pendingRatio * 100}% {isClaimable ? 'CLAIMABLE' : 'PENDING'}
              </TextNoWrap>{' '}
            </>
          )}
          {isClaimable && (
            <ActionLink action={onClaim}>Claim failed tasks</ActionLink>
          )}
        </span>
      ),
    }),
    ...(deal.botSize && {
      'Tasks count': deal.botSize,
    }),
    ...(deal.completedTasksCount && {
      'Completed tasks count': deal.completedTasksCount,
    }),
    ...(deal.claimedTasksCount && {
      'Claimed tasks count': deal.claimedTasksCount,
    }),
    ...(deal.startTime && {
      'Start time': (
        <>
          {moment(parseInt(deal.startTime) * 1000).fromNow()} (
          {moment(parseInt(deal.startTime) * 1000).toString()})
        </>
      ),
    }),
    ...(dealDeadline && {
      Deadline: (
        <>
          {moment(dealDeadline).fromNow()} ({moment(dealDeadline).toString()})
        </>
      ),
    }),
    ...(Array.isArray(deal?.dealEvents) && {
      Events: deal.dealEvents.map((dealEvent, i) => (
        <DealEvent {...dealEvent} chainId={chainId} key={i} />
      )),
    }),
  };

  return (
    <ContentWrapper>
      <Details title="Deal details" details={details} />
      {dealTasks?.tasks?.length > 0 && (
        <Tasks
          title="Tasks"
          minRows={0}
          hideColumns={['finalDeadline']}
          {...dealTasks}
        />
      )}
    </ContentWrapper>
  );
};

export default Deal;
