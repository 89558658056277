import Debug from 'debug';
import {
  all,
  put,
  takeLatest,
  fork,
  take,
  race,
  cancel,
  cancelled,
  call,
} from 'redux-saga/effects';
import { utils } from 'ethers';
import BN from 'bn.js';
import { request, gql } from 'graphql-request';
import IExecConfig from 'iexec/IExecConfig';
import IExecTaskModule from 'iexec/IExecTaskModule';
import { downloadZipApi } from '../../services/api';
import { multiWeb3, getWeb3 } from '../../services/web3';
import * as userActions from '../actions/user';
import * as notifierActions from '../actions/notifier';
import { ethersBnToBn } from '../../utils/maths';
import { capitalizeFirstLetter, taskResultToObject } from '../../utils/format';
import { getChainKey } from '../../utils/chain';
import { IPFS_GATEWAY_URL, SUBGRAPH_HTTP_URL } from '../../config';

const debug = Debug('saga:user');

const computeTaskId = (dealid, taskIdx) => {
  const encodedTypes = ['bytes32', 'uint256'];
  const values = [dealid, taskIdx];
  const encoded = utils.defaultAbiCoder.encode(encodedTypes, values);
  const taskid = utils.keccak256(encoded);
  return taskid;
};

function cancelable(cancelActions, saga) {
  return function* (...args) {
    const task = yield fork(saga, ...args);
    yield race(cancelActions.map((cancelAction) => take(cancelAction)));
    yield cancel(task);
  };
}

export function* downloadResult(action) {
  try {
    debug('downloadResult() action', action);
    const { taskid, results } = action;
    const { location } = taskResultToObject(results);

    yield put(
      notifierActions.notify({
        message: 'Fetching result from IPFS, this may take a while',
      }),
    );
    const downloadRes = yield downloadZipApi.get({
      api: IPFS_GATEWAY_URL,
      endpoint: location,
    });

    const file = yield downloadRes.blob();
    const fileName = `${taskid}.zip`;

    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveOrOpenBlob(file, fileName);
    else {
      var a = document.createElement('a'),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(function () {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
    yield put(userActions.downloadResultAsync.success());
  } catch (error) {
    debug('downloadResult()', error);
    yield put(
      userActions.downloadResultAsync.failure('failed to download result'),
    );
  }
}

export function* claimTask(action) {
  try {
    const { taskid } = action;
    const { provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const taskModule = IExecTaskModule.fromConfig(config);
    yield put(
      notifierActions.notify({
        message: `Open ${capitalizeFirstLetter(
          provider,
        )} to confirm the claim transaction`,
      }),
    );
    const txHash = yield call(taskModule.claim, taskid);
    yield put(userActions.claimTaskAsync.success(txHash));
  } catch (error) {
    debug('claim()', error);
    yield put(userActions.claimTaskAsync.failure('failed to claim task'));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.claimTaskAsync.cancelled());
    }
  }
}

export function* claimDeal(action) {
  try {
    const { deal } = action;
    const { botFirst, botSize, dealid } = deal;

    const { chainId, provider } = multiWeb3.getStatus();
    const web3 = getWeb3();
    const config = new IExecConfig({ ethProvider: web3 });
    const contracts = yield call(config.resolveContractsClient);

    const chainKey = getChainKey(chainId);
    const subgraphUrl = SUBGRAPH_HTTP_URL[chainKey];

    const allTasks = [...Array(botSize).keys()].map((i) => ({
      index: i + botFirst,
      taskid: computeTaskId(dealid, i + botFirst),
    }));

    const tasksQuery = gql`
      query getDealTasks($dealid: ID!, $first: Int!, $skip: Int!) {
        deal(id: $dealid) {
          tasks(
            first: $first
            orderBy: index
            orderDirection: asc
            skip: $skip
          ) {
            taskid: id
            index
            status
          }
        }
      }
    `;

    let initializedTasks = [];
    let taskPage = 0;
    const TASK_PAGE_LENGTH = 1000;
    while (taskPage >= 0) {
      const data = yield call(request, subgraphUrl, tasksQuery, {
        dealid,
        first: TASK_PAGE_LENGTH,
        skip: TASK_PAGE_LENGTH * taskPage,
      });
      const initializedTasksChunk =
        data?.deal?.tasks?.map((task) => ({
          ...task,
          index: parseInt(task.index),
        })) || [];
      if (initializedTasksChunk.length === TASK_PAGE_LENGTH) {
        taskPage = taskPage + 1;
      } else {
        taskPage = -1;
      }
      initializedTasks = [...initializedTasks, ...initializedTasksChunk];
    }

    const unsetTasks = allTasks.filter(
      (task) =>
        !initializedTasks.find(
          (initializedTask) => initializedTask.index === task.index,
        ),
    );

    const pendingTasks = initializedTasks.filter(
      (initializedTask) =>
        initializedTask.status !== 'COMPLETED' &&
        initializedTask.status !== 'FAILLED',
    );

    debug('initializedTasks', initializedTasks);
    debug('unsetTasks', unsetTasks);
    debug('pendingTasks', pendingTasks);

    const iexecContract = contracts.getIExecContract();

    const lastBlock = yield contracts.provider.getBlock('latest');
    const blockGasLimit = ethersBnToBn(lastBlock.gasLimit);
    debug('blockGasLimit', blockGasLimit.toString());

    // claim unset
    if (unsetTasks.length > 0) {
      const EST_GAS_PER_CLAIM = new BN(250000);
      const maxClaimPerTx = blockGasLimit.div(EST_GAS_PER_CLAIM);
      const claimTxNumber = new BN(unsetTasks.length)
        .div(maxClaimPerTx)
        .add(new BN(1));
      yield put(
        notifierActions.notify({
          message: `Open ${capitalizeFirstLetter(
            provider,
          )} to confirm the claim transactions for UNSTARTED tasks (${claimTxNumber} tx to confirm)`,
        }),
      );

      const processInitAndClaims = async (tasks) => {
        const dealidArray = new Array(tasks.length).fill(deal.dealid);
        const tx = await iexecContract.initializeAndClaimArray(
          dealidArray,
          tasks.map((task) => task.index),
          contracts.txOptions,
        );
        debug(`initializeAndClaimArray ${tx.hash} (${tasks.length} tasks)`);
        await tx.wait();
        return tx.hash;
      };

      while (unsetTasks.length > 0) {
        const unsetTasksToProcess = unsetTasks.splice(
          0,
          maxClaimPerTx.toNumber(),
        );
        yield call(processInitAndClaims, unsetTasksToProcess);
        yield put(
          notifierActions.notify({
            message: `Claimed ${unsetTasksToProcess.length} tasks`,
          }),
        );
      }
    }

    // claim initialized
    if (pendingTasks.length > 0) {
      const EST_GAS_PER_CLAIM = new BN(55000);
      const maxClaimPerTx = blockGasLimit.div(EST_GAS_PER_CLAIM);
      const claimTxNumber = new BN(pendingTasks.length)
        .div(maxClaimPerTx)
        .add(new BN(1));
      yield put(
        notifierActions.notify({
          message: `Open ${capitalizeFirstLetter(
            provider,
          )} to confirm the claim transactions for STARTED tasks (${claimTxNumber} tx to confirm)`,
        }),
      );

      const processClaims = async (tasks) => {
        const tx = await iexecContract.claimArray(
          tasks.map((task) => task.taskid),
          contracts.txOptions,
        );
        debug(`claimArray ${tx.hash} (${tasks.length} tasks)`);
        await tx.wait();
        return tx.hash;
      };

      while (pendingTasks.length > 0) {
        const pendingTasksToProcess = pendingTasks.splice(
          0,
          maxClaimPerTx.toNumber(),
        );
        yield call(processClaims, pendingTasksToProcess);
        yield put(
          notifierActions.notify({
            message: `Claimed ${pendingTasksToProcess.length} tasks`,
          }),
        );
      }
    }
  } catch (error) {
    debug('claim()', error);
    yield put(userActions.claimDealAsync.failure('failed to claim deal'));
  } finally {
    if (yield cancelled()) {
      yield put(userActions.claimDealAsync.cancelled());
    }
  }
}

export function* watchUser() {
  yield takeLatest('DOWNLOAD_RESULT_REQUEST', downloadResult);
  yield takeLatest(
    'CLAIM_TASK_REQUEST',
    cancelable(
      ['PROVIDER_NETWORK_CHANGED', 'PROVIDER_ACCOUNT_CHANGED'],
      claimTask,
    ),
  );
  yield takeLatest(
    'CLAIM_DEAL_REQUEST',
    cancelable(
      ['PROVIDER_NETWORK_CHANGED', 'PROVIDER_ACCOUNT_CHANGED'],
      claimDeal,
    ),
  );
}

export default function* userSaga() {
  yield all([watchUser()]);
}
