import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    borderLeft: `thick solid ${theme.palette.primary.light}`,
    margin: 20,
    padding: 10,
  },
});

const useStyles = makeStyles(styles);

const ContentWrapper = (props) => {
  const { children } = props;
  const classes = useStyles();

  return <div className={classes.root}>{children}</div>;
};

export default ContentWrapper;
