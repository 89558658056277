import React from 'react';
import { useSelector } from 'react-redux';
import { useSubscription, gql, useQuery } from '@apollo/client';
import Transaction from '../presentational/Transaction';
import { chainIdSelector } from '../../store/selectors/chain';

const TRANSACTION_SUBSCRIPTION = gql`
  subscription Transaction($search: ID!) {
    # transaction
    transaction(id: $search) {
      txHash: id
      from {
        address: id
      }
      to {
        address: id
      }
      gasLimit
      gasUsed
      gasPrice
    }
  }
`;

const EVENTS_QUERY = gql`
  query Events($search: String!) {
    # events
    appTransfers(where: { transaction: $search }) {
      id
      type: __typename
      app {
        address: id
      }
      from {
        address: id
      }
      to {
        address: id
      }
    }
    datasetTransfers(where: { transaction: $search }) {
      id
      type: __typename
      dataset {
        address: id
      }
      from {
        address: id
      }
      to {
        address: id
      }
    }
    workerpoolTransfers(where: { transaction: $search }) {
      id
      type: __typename
      workerpool {
        address: id
      }
      from {
        address: id
      }
      to {
        address: id
      }
    }
    rewards(where: { transaction: $search }) {
      id
      type: __typename
      account {
        address: id
      }
      value
      task {
        taskid: id
      }
    }
    seizes(where: { transaction: $search }) {
      id
      type: __typename
      account {
        address: id
      }
      value
      task {
        taskid: id
      }
    }
    locks(where: { transaction: $search }) {
      id
      type: __typename
      account {
        address: id
      }
      value
    }
    unlocks(where: { transaction: $search }) {
      id
      type: __typename
      account {
        address: id
      }
      value
    }
    accurateContributions(where: { transaction: $search }) {
      id
      type: __typename
      account {
        address: id
      }
    }
    faultyContributions(where: { transaction: $search }) {
      id
      type: __typename
      account {
        address: id
      }
    }
    policyUpdates(where: { transaction: $search }) {
      id
      type: __typename
      workerpool {
        address: id
      }
    }
    ordersMatcheds(where: { transaction: $search }) {
      id
      type: __typename
      deal {
        dealid: id
      }
    }
    schedulerNotices(where: { transaction: $search }) {
      id
      type: __typename
      deal {
        dealid: id
      }
      workerpool {
        address: id
      }
    }
    taskInitializes(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
    }
    taskContributes(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
      worker {
        address: id
      }
    }
    taskConsensuses(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
    }
    taskReveals(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
    }
    taskReopens(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
    }
    taskFinalizes(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
    }
    taskClaimeds(where: { transaction: $search }) {
      id
      type: __typename
      task {
        taskid: id
      }
    }
  }
`;

const castTransferEvent = (transferEvent) => {
  return {
    type: transferEvent?.type,
    app: transferEvent?.app?.address,
    dataset: transferEvent?.dataset?.address,
    workerpool: transferEvent?.workerpool?.address,
    from: transferEvent?.from?.address,
    to: transferEvent?.to?.address,
    amount: transferEvent?.amount,
  };
};

const castDealEvent = (dealEvent) => {
  return {
    type: dealEvent?.type,
    dealid: dealEvent?.deal?.dealid,
    workerpool: dealEvent?.workerpool?.address,
  };
};

const castTaskEvent = (taskEvent) => {
  return {
    type: taskEvent?.type,
    taskid: taskEvent?.task?.taskid,
    workerpool: taskEvent?.workerpool?.address,
    worker: taskEvent?.worker?.address,
    hash: taskEvent?.hash,
    consensus: taskEvent?.consensus,
    digest: taskEvent?.digest,
    results: taskEvent?.results,
  };
};

const castAccountEvent = (accountEvent) => {
  return {
    type: accountEvent?.type,
    address: accountEvent?.account?.address,
    value: accountEvent?.value,
    taskid: accountEvent?.task?.taskid,
  };
};

export default function TransactionContainer(props) {
  const { txHash } = props;
  const chainId = useSelector(chainIdSelector);

  const search = txHash?.toLowerCase();

  const txSubscription = useSubscription(TRANSACTION_SUBSCRIPTION, {
    variables: { search },
  });

  const txSubResults = !txSubscription.loading
    ? txSubscription?.data
    : undefined;

  const eventsQuery = useQuery(EVENTS_QUERY, {
    variables: { search },
  });

  const eventsQueryResults = !eventsQuery.loading
    ? eventsQuery?.data
    : undefined;

  const loading = txSubscription.loading || eventsQuery.loading;

  const transaction = {
    ...(txSubResults?.transaction && {
      txHash: txSubResults?.transaction?.txHash,
      from: txSubResults?.transaction?.from?.address,
      to: txSubResults?.transaction?.to?.address,
      gasLimit: txSubResults?.transaction?.gasLimit,
      gasUsed: txSubResults?.transaction?.gasUsed,
      gasPrice: txSubResults?.transaction?.gasPrice,
    }),
    events: [
      ...(eventsQueryResults?.appTransfers?.map(castTransferEvent) || []),
      ...(eventsQueryResults?.datasetTransfers?.map(castTransferEvent) || []),
      ...(eventsQueryResults?.workerpoolTransfers?.map(castTransferEvent) ||
        []),
      ...(eventsQueryResults?.rewards?.map(castAccountEvent) || []),
      ...(eventsQueryResults?.seizes?.map(castAccountEvent) || []),
      ...(eventsQueryResults?.locks?.map(castAccountEvent) || []),
      ...(eventsQueryResults?.unlocks?.map(castAccountEvent) || []),
      ...(eventsQueryResults?.accurateContributions || []),
      ...(eventsQueryResults?.faultyContributions || []),
      ...(eventsQueryResults?.policyUpdates || []),
      ...(eventsQueryResults?.ordersMatcheds?.map(castDealEvent) || []),
      ...(eventsQueryResults?.schedulerNotices?.map(castDealEvent) || []),
      ...(eventsQueryResults?.taskInitializes?.map(castTaskEvent) || []),
      ...(eventsQueryResults?.taskContributes?.map(castTaskEvent) || []),
      ...(eventsQueryResults?.taskConsensuses?.map(castTaskEvent) || []),
      ...(eventsQueryResults?.taskReveals?.map(castTaskEvent) || []),
      ...(eventsQueryResults?.taskReopens?.map(castTaskEvent) || []),
      ...(eventsQueryResults?.taskFinalizes?.map(castTaskEvent) || []),
      ...(eventsQueryResults?.taskClaimeds?.map(castTaskEvent) || []),
    ].sort((a, b) => (a.id > b.id ? 1 : -1)),
  };

  const passedProps = {
    chainId,
    loading,
    transaction,
  };

  return <Transaction {...passedProps} />;
}
